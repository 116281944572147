import { PointerEvent } from "react";
import { Tool } from "./Tool";
import type { BaseToolAction, ToolCursorType } from "./Tool.types";
import { Point } from "../../geometry/Point";
import { midPointBetween } from "../../geometry/midPointBetween";
import { ToolControlMap } from "./ToolControl.types";
import { Graphics as PixiGraphics } from "pixi.js";
import cloneDeep from "lodash.clonedeep";
import { pointerCoordinateFromEvent } from "../pointerCoordinateFromEvent";

export type PencilToolId = 'pencil';

export type PencilToolProperties = {
  brushColor: string;
  brushSize: number;
}

export type ModifiablePencilToolProperties = Pick<PencilToolProperties, 'brushColor' | 'brushSize'>;

export interface PencilToolAction extends BaseToolAction {
  toolId: PencilToolId;
  values: {
    segments: Point[];
    properties: PencilToolProperties;
  }
}

export class PencilTool extends Tool<PencilToolProperties, ModifiablePencilToolProperties> {
  override id: PencilToolId = "pencil";
  override type = 'freeform';
  override name = 'Pencil';
  override cursor: ToolCursorType = 'crosshair';

  override properties: PencilToolProperties = {
    brushColor: '#525FE1',
    brushSize: 4
  }

  override controlMap: ToolControlMap<ModifiablePencilToolProperties> = {
    brushColor: {
      type: "choices",
      options: [
        { label: 'Black', value: 'black' },
        { label: 'Purple', value: '#525FE1' },
        { label: 'Pink', value: '#FF55BB' },
        { label: 'Green', value: '#1A5D1A' },
      ]
    },
    brushSize: {
      type:  'choices',
      options: [
        { label: 'S', value: '4' },
        { label: 'M', value: '8' },
        { label: 'L', value: '12' },
        { label: 'XL', value: '16' },
      ]
    },
  }

  override propertySetters: SetterFns<ModifiablePencilToolProperties> = {
    setBrushColor: (brushColor) => {
      this.properties.brushColor = brushColor;
    },

    setBrushSize: (brushSize) => {
      this.properties.brushSize = brushSize;
    }
  };

  isPressing: boolean = false;
  isDrawing: boolean = false;
  segments: Point[] = [];

  onPointerMove(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.brushGraphics) {
      return;
    }

    const pointerCoordinate = pointerCoordinateFromEvent(event);

    if (this.isPressing) {
      this.isDrawing = true;
      this.segments.push(pointerCoordinate);
    } else {
      this.isDrawing = false;
    }

    if (this.isDrawing) {
      this.segments.push(pointerCoordinate);
      PencilTool.draw(this.editor.brushGraphics, this.segments, this.properties);
    }
  }

  onPointerUp(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = false;
    this.isDrawing = false;

    PencilTool.draw(this.editor.brushGraphics, this.segments, this.properties);

    this.editor.store.appendActionToHistory(
      this.recordAction({
        segments: this.segments,
        properties: this.properties
      })
    );

    // Clear the last drawing segments
    this.segments = [];
  }

  onPointerDown(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = true;

    this.segments.push(pointerCoordinateFromEvent(event));
  }

  onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.pointerGraphics) {
      return;
    }

    this.editor.pointerGraphics.clear();
    this.editor.pointerGraphics.beginFill(this.properties.brushColor);

    const { x, y } = pointerCoordinateFromEvent(event);
    this.editor.pointerGraphics.drawCircle(x, y, this.properties.brushSize / 2);
  }

  static draw(graphics: PixiGraphics, segments: Point[], properties: PencilToolProperties) {
    graphics.clear();

    let firstPoint = segments[0];
    let secondPoint = segments[1];

    if (!firstPoint) {
      return;
    }

    if (!secondPoint) {
      graphics.beginFill(properties.brushColor);
      graphics.drawCircle(firstPoint.x, firstPoint.y, properties.brushSize / 2);
      graphics.endFill();
      return;
    }

    graphics.lineStyle({ width: properties.brushSize, color: properties.brushColor });
    graphics.moveTo(secondPoint.x, secondPoint.y);
    for (let i = 1; i < segments.length; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point

      const midPoint = midPointBetween(firstPoint, secondPoint);
      graphics.quadraticCurveTo(
        firstPoint.x,
        firstPoint.y,
        midPoint.x,
        midPoint.y
      );

      firstPoint = segments[i];
      secondPoint = segments[i + 1];
    }

    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    graphics.lineTo(firstPoint.x, firstPoint.y);
  }

  private recordAction(values: PencilToolAction['values']): PencilToolAction {
    return {
      toolId: this.id,
      values: cloneDeep(values)
    }
  }
}

/**
 * A guard to check whether or not the given {@link tool} is a {@link PencilTool}.
 *
 * @param tool - The tool to check.
 * @returns `true` if {@link tool} is a {@link PencilTool}, otherwise `false`.
 */
export const isPencilTool = (tool: Pick<Tool, 'id'>): tool is PencilTool => {
  return tool.id === 'pencil';
}
