import { useEffect } from "react";
import { useEditorContainer } from "./useEditorContainer"
import { useEditorStore } from "../../data/editor.store";

export const useCursor = () => {
  const editorContainer = useEditorContainer();
  const activeTool = useEditorStore((store) => store.activeTool);

  useEffect(() => {
    const cursorType = activeTool?.cursor;

    if (cursorType) {
      editorContainer.style.cursor = cursorType;
    }
  }, [editorContainer, activeTool])
}
