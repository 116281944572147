import { Point } from "./Point";

/**
 * Determine the mid-point between two given points.
 *
 * @param pointA - The first point
 * @param pointB - The second point
 * @returns The mid-point between between {@link pointA} and {@link pointB}.
 */
export const midPointBetween = (pointA: Point, pointB: Point): Point => {
  return {
    x: pointA.x + (pointB.x - pointA.x) / 2,
    y: pointA.y + (pointB.y - pointA.y) / 2
  };
}
