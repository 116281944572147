import { useCallback, useEffect, useRef, useState } from 'react';
import { CircleSlash, Plus } from 'lucide-react';
import { TRANSPARENT_COLOR } from '../../lib/editor/colors';
import { Icon, IconName } from './Icon';
import { HexColorPicker } from "react-colorful";
import './ButtonGroup.scss';
import useClickOutside from '../../lib/editor/useClickOutside';

type ButtonGroupVariant = 'color' | 'label' | 'icon' | 'size' | 'text';

type ButtonGroupOption = {
  name: string;
  value: string;
  color?: string;
}

type ButtonGroupProps = {
  variant: ButtonGroupVariant;
  options: ButtonGroupOption[];
  selectedOptionValue: ButtonGroupOption['value'];
  onSelect: (optionValue: ButtonGroupOption['value']) => void;
}

export const ButtonGroup = (
  { variant, options, selectedOptionValue, onSelect }: ButtonGroupProps
) => {
  const renderButtonForVariant = (variant: ButtonGroupVariant, option: ButtonGroupOption) => {
    const isSelected = option.value === selectedOptionValue;

    switch (variant) {
      case 'color':
        return <ColorButton key={option.value} option={option} onSelect={onSelect} isSelected={isSelected} />

      case 'label':
        return <LabelButton key={option.value} option={option} onSelect={onSelect} isSelected={isSelected} />

      case 'icon':
        return <IconButton key={option.value} option={option} onSelect={onSelect} isSelected={isSelected} />

      case 'size':
        return <SizeButton key={option.value} option={option} onSelect={onSelect} isSelected={isSelected} />

        case 'text':
          return <TextButton key={option.value} option={option} onSelect={onSelect} isSelected={isSelected} />

      default:
        console.warn(`Attempted to render an unsupported button group variant: '${variant}'. Rendered nothing instead.`);
        return null;
    }
  }

  return (
    <div className="button-group">
      {options.map((option) => renderButtonForVariant(variant, option))}
      {variant === 'color' && (
        // Always selected if previous options are not. This is due to how the renderer determines
        // which option is selected, but since we continuously update this value, the match will
        // fail if comparing statically.
        <CustomColorButton
          key='custom'
          option={{ name: 'Custom', value: '#fff' }}
          onSelect={onSelect}
          isSelected={!options.some((option => option.value === selectedOptionValue))}
        />
      )}
    </div>
  )
}

type ButtonProps = {
  option: ButtonGroupOption;
  isSelected: boolean;
  onSelect: (optionValue: string) => void;
}

const LabelButton = ({ option, isSelected, onSelect }: ButtonProps) => {
  return (
    <button
      className={`button-group-option button-group-option--label ${isSelected ? 'button-group-option--selected' : ''}`}
      onClick={() => onSelect(option.value)}
    >
      {option.name}
    </button>
  )
}

const ColorButton = ({ option, isSelected, onSelect }: ButtonProps) => {
  return (
    <button
      className={`button-group-option button-group-option--color ${isSelected ? 'button-group-option--selected' : ''}`}
      onClick={() => onSelect(option.value)}
    >
      {option.value === TRANSPARENT_COLOR ? (
        <CircleSlash className='button-group-option--color__circle' />
      ) : (
        <div style={{ backgroundColor: option.value }} className="button-group-option--color__circle" />
      )}
    </button>
  )
}

const CustomColorButton = ({ option, isSelected, onSelect }: ButtonProps) => {
  const [color, setColor] = useState<string>(option.value);
  const [isOpen, toggle] = useState<boolean>(false);

  const popover = useRef<HTMLDivElement>(null);
  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  useEffect(() => {
    if (isOpen) {
      onSelect(color);
    }
  }, [isOpen, onSelect, color]);

  return (
    <button
      className={`button-group-option button-group-option--color ${isSelected ? 'button-group-option--selected' : ''}`}
      onClick={() => {
        toggle(true);
        onSelect(color);
      }}
    >
      <Plus style={{ backgroundColor: color }} className="button-group-option--color__circle" />
      <div className={`button-group-option--color__modal ${isOpen ? 'button-group-option--color__modal--open' : ''}`} ref={popover}>
        <HexColorPicker color={color} onChange={setColor} />
      </div>
    </button>
  )
}

const IconButton = ({ option, isSelected, onSelect }: ButtonProps) => {
  return (
    <button
      className={`button-group-option button-group-option--icon ${isSelected ? 'button-group-option--selected' : ''}`}
      onClick={() => onSelect(option.value)}
    >
      <Icon name={option.name as IconName} />
    </button>
  )
}

const SizeButton = ({ option, isSelected, onSelect }: ButtonProps) => {
  const size = Number(option.value);

  return (
    <button
      className={`button-group-option button-group-option--size ${isSelected ? 'button-group-option--selected' : ''}`}
      onClick={() => onSelect(option.value)}
    >
      <div style={{
        backgroundColor: option.color, width: size, height: size }} className="button-group-option--color__circle" />
    </button>
  )
}


const TextButton = ({ option, isSelected, onSelect }: ButtonProps) => {
  const size = Number(option.value);

  return (
    <button
      className={`button-group-option button-group-option--size ${isSelected ? 'button-group-option--selected' : ''}`}
      onClick={() => onSelect(option.value)}
    >
      <span style={{ color: option.color, fontSize: size / 3 }}>Aa</span>
    </button>
  )
}
