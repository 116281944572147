import { Table } from "nhsuk-react-components"
import './EmptyTable.scss';

type EmptyTableProps = {
  message: string;
}

export const EmptyTable = ({ message }: EmptyTableProps) => {
  return (
    <Table className="empty-table">
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <h3 className="empty-table--message">
              {message}
            </h3>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
