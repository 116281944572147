import { useLocation } from 'react-router-dom';
import { Whiteboard } from '../components/Whiteboard';

export type WhiteboardPageState = {
  layoutFileName: string;
  patientResourceId: string;
  encounterResourceId: string;
}

export const WhiteboardPage = () => {
  const { layoutFileName, patientResourceId, encounterResourceId } = useLocation().state as WhiteboardPageState;

  return (
    <>
      <Whiteboard
        patientResourceId={patientResourceId}
        encounterResourceId={encounterResourceId}
        layoutFileName={layoutFileName}
      />
    </>
  )
}
