import { icons } from 'lucide-react';

export type IconName = keyof typeof icons;

type IconProps = {
  name: IconName;
  className?: Element['className'];
}

export const Icon = ({ name, className }: IconProps): JSX.Element | null => {
  const LucideIcon = icons[name];

  if (!LucideIcon) {
    return null;
  }

  return <LucideIcon className={className} />
}
