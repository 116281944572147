import { Point } from "./Point";

/**
 * Returns the distance (hypotenuse) between the two points {@link pointA} and {@link pointB}
 * using the Pythagoras Theorem.
 *
 *   Formula:
 *   --------
 *     c = sqrt(a^2 + b^2)
 *
 *            or
 *
 *     c^2 = a^2 + b^2
 *
 *   Legend:
 *   -------
 *     c   hypotenuse
 *     a   side of the triangle
 *     b   other side of the triangle
 *
 *   Visual rep:
 *   -----------
 *   ```
 *   (pointA) or (pointB)
 *       |\
 *       |  \  c
 *     a |    \
 *       |      \
 *       +-------+ (pointB) or (pointA)
 *          b
 *   ```
 *
 * @param pointA - The point to measure the distance from.
 * @param pointB - The other point to measure the distance to.
 *
 * @returns The distance (hypotenuse) between {@link pointA} and {@link pointB}.
 */
export const distanceBetweenPoints = (pointA: Point, pointB: Point): number => {
  const sideA = pointA.x - pointB.x;
  const sideB = pointA.y - pointB.y;

  const distance = Math.sqrt((sideA * sideA) + (sideB * sideB));
  return distance;
}
