import { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";
import { getAccessTokenSilently } from "../../../lib/auth/getAccessToken";

/**
 * Attempts to fetch and inject the access token for the currently authenticated user as a Bearer token in the
 * request header.
 * - If not authenticated, then it won't apply the Bearer token to the request.
 * - Interceptor for the {@link apiClient}.
 *
 * @param config - Axios request configuration object reference of which we'll be modifying its {@link headers} attribute to
 * inject the Bearer token if necessary.
 *
 * @returns the updated {@link InternalAxiosRequestConfig} object with the injected Bearer token (if applicable) to then
 * be used in the next HTTP request via the {@link apiClient}.
 */
export const applyAccessTokenToRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const accessToken = await getAccessTokenSilently();

  if (accessToken) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }

    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};
