import { Button, Details, Select } from "nhsuk-react-components"
import { FormEvent } from "react";
import { ToolControls } from "./ToolControls";
import { useEditorStore } from "../../data/editor.store";
import { HorizontalRule } from "../common/HorizontalRule";
import './Toolbar.scss';

type ToolbarProps = {
  onClear: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onSave: () => void;
}

export const Toolbar = ({
  onUndo,
  onRedo,
  onClear,
  onSave,
}: ToolbarProps) => {
  const [tools, activeTool, setActiveToolById, canUndo, canRedo] = useEditorStore((store) => [
    store.tools, store.activeTool, store.setActiveToolById, store.canUndo, store.canRedo
  ]);

  const toolChangeHandler = (event: FormEvent<HTMLSelectElement>): void => {
    const toolId = event.currentTarget.value;
    setActiveToolById(toolId);
  }

  return (
    <div className="toolbar">
      <Details open={true} expander className="toolbar__expander">
        <Details.Summary className="toolbar__expander__summary">Tools</Details.Summary>
        <div className="toolbar__expander__container">
          <Select label="Select a tool:" onChange={toolChangeHandler} value={activeTool?.id}>
            {tools.map((tool) => (
              <Select.Option key={tool.id} value={tool.id}>{tool.name}</Select.Option>
            ))}
          </Select>

          <HorizontalRule />

          <Button disabled={!canUndo()} secondary onClick={onClear}>Clear</Button>

          <div className="toolbar__history-buttons">
            <Button disabled={!canUndo()} onClick={onUndo}>Undo</Button>
            <Button disabled={!canRedo()} onClick={onRedo}>Redo</Button>
          </div>

          <ToolControls />

          <Button onClick={onSave}>Save</Button>
        </div>
      </Details>
    </div>
  )
}
