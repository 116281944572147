import { TextMetrics as PixiTextMetrics, TextStyle as PixiTextStyle } from 'pixi.js';

/**
 * The cached / indexed width for a character.
 * Useful for performant text measurements to avoid re-calculating an already calculated character's width.
 *
 */
export type CharacterWidthCache = Record<string, number>;

/**
 * An array of distances from start of the text line up to the character.
 *
 * ```text
 * E.g: For each line in a "HELLO WORLD" text, where each character is 1px wide, we will have we'll have:
 *                       Line 1    =>    HELLO
 *  Line 1 widths per character    =>    12345
 *                       Line 2    =>    WORLD
 *  Line 1 widths per character    =>    12345
 *
 *  Interpretation:
 *    H - its width up to this point in this line is 1px.
 *    E - its width up to this point in this line is 2px, because WIDTH_OF(H + E) = 2px;
 *    L - its width up to this point in this line is 3px, because WIDTH_OF(H + E + L) = 3px;
 *    ...
 *    W - its width up to this point in this line is 1px (note: its on a new line).
 *    O - its width up to this point in this line is 2px, because WIDTH_OF(W + O) = 2px;
 *    R - its width up to this point in this line is 3px, because WIDTH_OF(W + O + R) = 3px;
 *    ...
 * ```
 *
 * @see measureDistancesToCharactersPerLine
 * @example
 * ```ts
 * const text = "Hello\nWorld";
 * const textLines = text.split('\n');
 * textLines === [
 *  "Hello",
 *  "World"
 * ];
 *
 * const distances: DistancesToCharactersPerLine = measureDistancesToCharactersPerLine(text, textStyle, cache);
 * distances === [
 *  [1, 2, 3, 4, 5],
 *  [1, 2, 3, 4, 5]
 * ]
 *
 * ```
 */
export type DistancesToCharactersPerLine = number[][];

/**
 * Measures the distance from start up to the character, per line.
 *
 * @param text - the text to measure.
 * @param textStyle - the text style being measured.
 * @param characterWidthCache - the cache object to use so instead of re-measuring the same character in the text, it
 * will pick the existing measured width if it previously appeared in the {@link text}.
 */
export const measureDistancesToCharactersPerLine = (
  text: string,
  textStyle: PixiTextStyle,
  characterWidthCache: CharacterWidthCache,
): DistancesToCharactersPerLine => {
  const textLines = text.split('\n');

  const distances: DistancesToCharactersPerLine = [];

  for (const textLine of textLines) {
    const lineDistances: number[] = [];
    let distanceFromStartOfLine = 0;

    for (const char of textLine) {
      const cachedCharWidth = characterWidthCache[char];
      if (cachedCharWidth != null) {
        // Use the cached character width
        distanceFromStartOfLine += cachedCharWidth;
        lineDistances.push(distanceFromStartOfLine);
      } else {
        // Measure & cache this character's width
        const charMeasurements = PixiTextMetrics.measureText(char, textStyle);

        characterWidthCache[char] = charMeasurements.width;

        distanceFromStartOfLine += charMeasurements.width;
        lineDistances.push(distanceFromStartOfLine);
      }
    }
    distances.push(lineDistances);
  }

  return distances;
}
