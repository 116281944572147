import { useMemo, useRef, useState } from "react"
import { ApiListResponse, ApiPaginationParams } from "../../api/types";
import { Draft } from "immer";
import { useImmer } from "use-immer";
import { emptyPaginationParams, parsePaginationParams } from "../api/paginationParams";

export const usePaginatedQuery = <TArgs = undefined, TListItem = unknown, UListResponse extends ApiListResponse<TListItem> = ApiListResponse<TListItem>>(
  {
    requestFn,
    willLoadInitially
  }: {
    requestFn: (args: TArgs | undefined, paginationParams: ApiPaginationParams) => Promise<UListResponse | undefined>,
    willLoadInitially?: boolean
  }
) => {
  const [dataSets, setDataSets] = useImmer<TListItem[][]>([]);

  const currPageArgsRef = useRef<TArgs | undefined>();
  const currPagePaginationParamsRef = useRef<ApiPaginationParams>(emptyPaginationParams());
  const nextPagePaginationParamsRef = useRef<ApiPaginationParams>(emptyPaginationParams());

  const [nextPagePaginationParams, setNextPagePaginationParams] = useState<ApiPaginationParams>(emptyPaginationParams());

  const [loading, setLoading] = useState<boolean>(!!willLoadInitially);
  const [error, setError] = useState<Error | undefined>();

  const load = async (args?: TArgs) => {
    // Load with new arguments
    return loader(args, { shouldReload: false, replace: true });
  }

  const reload = async () => {
    return loader(currPageArgsRef.current, { shouldReload: true });
  }

  const nextPage = async () => {
    return loader(currPageArgsRef.current, { shouldReload: false });
  }

  const loader = async (args: TArgs | undefined, { shouldReload, replace }: { shouldReload: boolean, replace?: boolean }): Promise<void> => {
    try {
      if (replace) {
        // Clear all dataSets and previous params
        setDataSets([]);
        currPageArgsRef.current = undefined;
        currPagePaginationParamsRef.current = emptyPaginationParams();
        nextPagePaginationParamsRef.current = emptyPaginationParams();
        setNextPagePaginationParams(emptyPaginationParams());
      }

      // Fetch the data
      setError(undefined);
      setLoading(true);

      // Store the current page params. This is useful for the reload api.
      currPageArgsRef.current = args;

      if (!shouldReload) {
        currPagePaginationParamsRef.current = nextPagePaginationParamsRef.current;
      }

      const thisReqPaginationParams = shouldReload
        ? currPagePaginationParamsRef.current
        : nextPagePaginationParamsRef.current;

      const response = await requestFn(args, thisReqPaginationParams);
      if (response === undefined) {
        return;
      }

      setDataSets((drafts) => {
        if (shouldReload) {
          // Remove the last data loaded, to replace with the reloaded data
          drafts.pop();
        }

        const newDataSet = response.data as Draft<typeof response.data>;
        if (replace) {
          // Replace the current data set.
          return newDataSet;
        } else {
          // Push the reloaded data set to the existing list
          drafts.push(newDataSet);
        }
      });

      const parsedPaginationParams = parsePaginationParams(response.paginationLinks.nextPageUrl)
      nextPagePaginationParamsRef.current = parsedPaginationParams;
      setNextPagePaginationParams(parsedPaginationParams);

      setError(undefined);
    } catch (error: unknown) {
      setError(error as Error);

    } finally {
      setLoading(false);
    }
  }

  const data: TListItem[] = useMemo(() => {
    return dataSets.flat();
  }, [dataSets]);

  const hasNextPage = () => {
    return nextPagePaginationParams.pageQueryId !== null;
  }

  return {
    data,
    load,
    reload,
    nextPage,
    nextPagePaginationParams,
    hasNextPage,
    loading,
    error
  }
}
