import { capitalize } from "./capitalize";

/**
 * Transform the input {@link name} string into a setter method name for itself, by the word `set`
 * and capitalizing the first letter of the input string.
 *
 * @param name - The name to transform.
 * @example
 * ```ts
 * const myRecordKey: string = 'age';
 * const setterMethodName: string = setterify(myRecordKey);
 * //=> 'setAge';
 * ```
 */
export const setterify = (name: string) => {
  return `set${capitalize(name)}`;
}
