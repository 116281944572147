import type { KeyboardEvent, PointerEvent } from 'react';
import type { Editor } from '../Editor';
import type { ToolCursorType, ToolId } from './Tool.types';
import { Application as PixiApplication } from 'pixi.js';
import { ToolControlMap } from './ToolControl.types';

export abstract class Tool<
  TProperties extends Record<string, any> = Record<string, any>,
  TModifiableProperties extends Record<string, any> = Record<string, any>
> {
  editor: Editor;
  app: PixiApplication | undefined;
  isActive: boolean = false;

  abstract id: ToolId;
  abstract type: string;
  abstract name: string;
  abstract cursor: ToolCursorType;
  abstract properties: TProperties;
  abstract controlMap: ToolControlMap<TModifiableProperties>;
  abstract propertySetters: SetterFns<TModifiableProperties>;

  constructor(editor: Editor, app?: PixiApplication) {
    this.editor = editor;
    this.app = app;
  }

  public activate(): void {
    this.isActive = true;
  }

  public deactivate(): void {
    this.isActive = false;

    if (this.editor.activeTool === this) {
      this.editor.activeTool = undefined;
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    // Most of the tools don't require the keyUp event, so we're not providing this as an abstract method.
    // - Components requiring this method should override this, otherwise don't to do nothing.
  };

  abstract onPointerUp(event: PointerEvent<HTMLCanvasElement>): void;
  abstract onPointerMove(event: PointerEvent<HTMLCanvasElement>): void;
  abstract onPointerDown(event: PointerEvent<HTMLCanvasElement>): void;
  abstract onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void;
}
