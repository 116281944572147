import { SilentRequest } from '@azure/msal-browser'
import { cachedMsalInstance } from '../../lib/auth/cachedMsalInstance'
import { msalConfig } from './msalConfig'

/**
 * Returns the an MSAL Access Token Request setting to use with
 * {@link msalInstance.acquireTokenSilent}.
 */
export const getMsalAccessTokenRequest = (): SilentRequest => {
  return {
    scopes: [`${msalConfig.auth.clientId}/.default`],
    account: cachedMsalInstance().getActiveAccount() ?? undefined,
  }
}
