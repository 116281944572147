import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { msalLoginRequest } from "../config/msal/msalLoginRequest";
import { WhiteboardPage } from "./WhiteboardPage";
import { LoginCompletePage } from "./auth/LoginCompletePage";
import { ProtectedRoute } from "../components/auth/ProtectedRoute";
import { useActiveAccountManager } from "../lib/hooks/useActiveAccountManager";
import { PatientSelectionPage } from "./PatientSelectionPage";
import { AppMainLayout } from "../components/layouts/AppMainLayout";
import { AppSimpleLayout, AppSimplePatientLayout } from "../components/layouts/AppSimpleLayout";

export const baseUrl: string | undefined = document.querySelector('base')?.getAttribute('href') ?? undefined;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ProtectedRoute />}>
      <Route element={<AppMainLayout />}>
        <Route index path="/" element={<PatientSelectionPage />} />
      </Route>

      <Route element={<AppSimplePatientLayout />}>
        <Route index path="/whiteboard" element={<WhiteboardPage />} />
      </Route>
      <Route element={<AppSimpleLayout />}>
        <Route path="/auth/login/complete" element={<LoginCompletePage />} />
      </Route>
    </Route>
  ),
  { basename: baseUrl }
);

export const Router = () => {
  /**
   * Initiates a login if a user is not already signed in,
   * otherwise attempt to acquire a token.
   */
  useMsalAuthentication(InteractionType.Redirect, msalLoginRequest);
  useActiveAccountManager();

  return (
    <RouterProvider router={router} />
  )
}
