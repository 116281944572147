import { Fieldset } from "nhsuk-react-components"
import { ModifiableRectangleShapeToolProperties, RectangleShapeTool } from "../../../../lib/editor/tools/RectangleShapeTool"
import { ButtonGroup } from "../../../common/ButtonGroup"
import { useCallback } from "react"
import { useEditor } from "../../../../lib/hooks/useEditor"

type RectangleShapeToolControllerProps = {
  rectangleShapeTool: RectangleShapeTool
}

export const RectangleShapeToolController = ({ rectangleShapeTool }: RectangleShapeToolControllerProps) => {
  const editor = useEditor();

  const onStrokeColorSelect = useCallback((strokeColor: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableRectangleShapeToolProperties>>({
      strokeColor
    });
  }, [editor]);

  const onFillColorSelect = useCallback((fillColor: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableRectangleShapeToolProperties>>({
      fillColor
    });
  }, [editor]);

  const onStrokeSizeSelect = useCallback((strokeSize: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableRectangleShapeToolProperties>>({
      strokeSize: parseInt(strokeSize)
    });
  }, [editor]);

  return (
    <>
      {rectangleShapeTool.controlMap.strokeColor.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Border color:</Fieldset.Legend>

          <ButtonGroup
            variant="color"
            onSelect={onStrokeColorSelect}
            selectedOptionValue={rectangleShapeTool?.properties.strokeColor}
            options={rectangleShapeTool.controlMap.strokeColor.options.map((strokeColorOption) => ({
              name: strokeColorOption.label,
              value: strokeColorOption.value
            }))}
          />
        </Fieldset>
      ) : null}

      {rectangleShapeTool.controlMap.fillColor.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Background color:</Fieldset.Legend>

          <ButtonGroup
            variant="color"
            onSelect={onFillColorSelect}
            selectedOptionValue={rectangleShapeTool?.properties.fillColor}
            options={rectangleShapeTool.controlMap.fillColor.options.map((fillColorOption) => ({
              name: fillColorOption.label,
              value: fillColorOption.value
            }))}
          />
        </Fieldset>
      ) : null}

      {rectangleShapeTool.controlMap.strokeSize.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Border size:</Fieldset.Legend>

          <ButtonGroup
            variant="size"
            onSelect={onStrokeSizeSelect}
            selectedOptionValue={rectangleShapeTool?.properties.strokeSize.toString()}
            options={rectangleShapeTool.controlMap.strokeSize.options.map((strokeSizeOption) => ({
              name: strokeSizeOption.label,
              value: strokeSizeOption.value,
              color: editor.store.activeTool?.properties.strokeColor
            }))}
          />
        </Fieldset>
      ) : null}
    </>
  )
}
