import { useEditorStore } from "../../data/editor.store";
import { isPencilTool } from "../../lib/editor/tools/PencilTool";
import { isRectangleShapeTool } from "../../lib/editor/tools/RectangleShapeTool";
import { isEllipseShapeTool } from "../../lib/editor/tools/EllipseShapeTool";
import { isPenTool } from "../../lib/editor/tools/PenTool";
import { isArrowTool } from "../../lib/editor/tools/ArrowTool";
import { PencilToolController } from "./tools/controllers/PencilToolController";
import { RectangleShapeToolController } from "./tools/controllers/RectangleShapeToolController";
import { EllipseShapeToolController } from "./tools/controllers/EllipseShapeToolController";
import { PenToolController } from "./tools/controllers/PenToolController";
import { ArrowToolController } from "./tools/controllers/ArrowToolController";
import { isTextTool } from "../../lib/editor/tools/TextTool";
import { TextToolController } from "./tools/controllers/TextToolController";
import { isLineTool } from "../../lib/editor/tools/LineTool";
import { LineToolController } from "./tools/controllers/LineToolController";
import { isEraserTool } from "../../lib/editor/tools/EraserTool";
import { EraserToolController } from "./tools/controllers/EraserToolController";
import './ToolControls.scss';

export const ToolControls = () => {
  const activeTool = useEditorStore((store) => store.activeTool);

  const renderControlsForActiveTool = () => {
    if (!activeTool) {
      return null;
    }

    if (isPencilTool(activeTool)) {
      return <PencilToolController pencilTool={activeTool} />

    } else if (isRectangleShapeTool(activeTool)) {
      return <RectangleShapeToolController rectangleShapeTool={activeTool} />

    } else if (isEllipseShapeTool(activeTool)) {
      return <EllipseShapeToolController ellipseShapeTool={activeTool} />

    } else if (isPenTool(activeTool)) {
      return <PenToolController penTool={activeTool} />

    } else if (isLineTool(activeTool)) {
      return <LineToolController lineTool={activeTool} />

    } else if (isArrowTool(activeTool)) {
      return <ArrowToolController arrowTool={activeTool} />

    } else if (isTextTool(activeTool)) {
      return <TextToolController textTool={activeTool} />
    
    } else if (isEraserTool(activeTool)) {
      return <EraserToolController eraserTool={activeTool} />
    }

    return "No controls for this tool."
  }

  return (
    <div className="tool-controls">
      {renderControlsForActiveTool()}
    </div>
  )
}
