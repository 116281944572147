import { useCallback } from "react"
import { Fieldset } from "nhsuk-react-components"
import { ModifiableArrowToolProperties, ArrowTool, ArrowHeadPosition } from "../../../../lib/editor/tools/ArrowTool"
import { ButtonGroup } from "../../../common/ButtonGroup"
import { useEditor } from "../../../../lib/hooks/useEditor"

type ArrowToolControllerProps = {
  arrowTool: ArrowTool
}

export const ArrowToolController = ({ arrowTool }: ArrowToolControllerProps) => {
  const editor = useEditor();

  const onStrokeColorSelect = useCallback((strokeColor: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableArrowToolProperties>>({
      strokeColor
    });
  }, [editor]);

  const onStrokeSizeSelect = useCallback((strokeSize: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableArrowToolProperties>>({
      strokeSize: parseInt(strokeSize)
    });
  }, [editor]);

  const onArrowHeadPositionSelect = useCallback((arrowHeadPosition: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableArrowToolProperties>>({
      arrowHeadPosition: arrowHeadPosition as ArrowHeadPosition
    });
  }, [editor]);

  return (
    <>
      {arrowTool.controlMap.strokeColor.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Border color:</Fieldset.Legend>

          <ButtonGroup
            variant="color"
            onSelect={onStrokeColorSelect}
            selectedOptionValue={arrowTool.properties.strokeColor}
            options={arrowTool.controlMap.strokeColor.options.map((strokeColorOption) => ({
              name: strokeColorOption.label,
              value: strokeColorOption.value
            }))}
          />
        </Fieldset>
      ) : null}

      {arrowTool.controlMap.strokeSize.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Border size:</Fieldset.Legend>

          <ButtonGroup
            variant="size"
            onSelect={onStrokeSizeSelect}
            selectedOptionValue={arrowTool.properties.strokeSize.toString()}
            options={arrowTool.controlMap.strokeSize.options.map((strokeSizeOption) => ({
              name: strokeSizeOption.label,
              value: strokeSizeOption.value,
              color: editor.store.activeTool?.properties.strokeColor
            }))}
          />
        </Fieldset>
      ) : null}

      {arrowTool.controlMap.arrowHeadPosition.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Arrow head position:</Fieldset.Legend>

          <ButtonGroup
            variant="label"
            onSelect={onArrowHeadPositionSelect}
            selectedOptionValue={arrowTool.properties.arrowHeadPosition}
            options={arrowTool.controlMap.arrowHeadPosition.options.map((arrowHeadPositionOption) => ({
              name: arrowHeadPositionOption.label,
              value: arrowHeadPositionOption.value
            }))}
          />
        </Fieldset>
      ) : null}
    </>
  )
}
