import { AxiosError } from "axios";
import { reportErrorToAirbrake } from "../../../lib/airbrake/reportErrorToAirbrake";

export const reportApiErrorsFromApiResponse = async (error: AxiosError) => {
  if (process.env.NODE_ENV !== 'production') {
    // Report the error to Airbrake (errbit) in prod.
    reportErrorToAirbrake(error);
  }

  return Promise.reject(error);
}
