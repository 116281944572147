import { Button } from "nhsuk-react-components"
import { HorizontalRule } from "./HorizontalRule";

/**
 * @file This is a custom component added by The Curve, as the `nhsuk-react-components` did not provided one.
 *
 * Use case:
 * - Display a friendly error message to the user with an optional ability for the user to retry the action that
 * caused the error. E.g: When data for a list fails to fetch.
 *
 * @see https://service-manual.nhs.uk/design-system/components/error-summary - To see where the inspiration for this
 * design was taken.
 */

type ErrorCardProps = {
  message: string;
  onRetry?: () => void;
}

export const ErrorCard = ({ message, onRetry }: ErrorCardProps) => {
  return (
    <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert">
      <h2 className="nhsuk-error-summary__title" id="error-summary-title">
        There is a problem
      </h2>

      <div className="nhsuk-error-summary__body">
        <p style={{ marginBottom: 0 }}>
          {message}
        </p>

        {onRetry ? (
          <>
            <HorizontalRule />

            <Button secondary onClick={onRetry} style={{ marginBottom: 0 }}>
              Retry
            </Button>
          </>
        ): null}
      </div>
    </div>
  )
}
