import { ReactNode, createContext, useContext } from "react"

type EditorContainerProviderProps = {
  container: HTMLDivElement,
  children: ReactNode
}

const EditorContainerContext = createContext<HTMLDivElement | null>(null);

export const EditorContainerProvider = ({ container, children }: EditorContainerProviderProps) => {
	return (
    <EditorContainerContext.Provider
      value={container}
      children={children} />
  )
}

export function useEditorContainer(): HTMLDivElement {
  const value = useContext(EditorContainerContext);
  if (!value) {
    throw new Error('useEditorContainer used outside of <EditorContainerProvider />');
  }

  return value;
}
