import { create } from 'zustand';
import { Point } from '../lib/geometry/Point';

export type TextStore = {
  text: string;

  /**
    * Whether or not the use is currently in typing mode.
   */
  isTyping: boolean;

  /**
   * The top-left edge of the input box to use as the position for drawing the Text graphics the Canvas element.
   */
  pointInCanvas?: Point;

  /**
   * The top-left edge of the input box to use as the position for displaying the real and editable TextInput element
   * in the HTNL document.
   */
  pointInDocument?: Point;

  setText: (text: string) => void;

  startTypingAt: (pointInCanvas: Point, pointInDocument: Point) => void;
  stopTypingAndReset: () => void;
}

const defaultState: OmitFunctions<TextStore> = {
  text: '',
  isTyping: false,
  pointInCanvas: undefined,
  pointInDocument: undefined,
}

export const useTextStore = create<TextStore>()(
  (set) => ({
    ...defaultState,

    setText: (text) => {
      set({ text });
    },

    startTypingAt: (pointInCanvas, pointInDocument) => {
      set({
        isTyping: true,
        pointInCanvas,
        pointInDocument
      });
    },

    stopTypingAndReset: () => {
      set(defaultState);
    }
  })
);

