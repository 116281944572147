import { useCallback, useRef, useState, PointerEvent, KeyboardEvent, useEffect, MouseEvent } from "react";
import { Graphics, Sprite, Stage, Container } from "@pixi/react";
import { Graphics as PixiGraphics, Sprite as PixiSprite, RenderTexture as PixiRenderTexture, Application as PixiApplication } from "pixi.js";
import { PencilTool } from "../lib/editor/tools/PencilTool";
import { Editor } from "../lib/editor/Editor";
import { Toolbar } from "./editor/Toolbar";
import { RectangleShapeTool } from "../lib/editor/tools/RectangleShapeTool";
import { useEditorStore } from "../data/editor.store";
import { useHotkeys } from 'react-hotkeys-hook'
import { Key } from "ts-key-enum";
import { EllipseShapeTool } from "../lib/editor/tools/EllipseShapeTool";
import { ArrowTool } from "../lib/editor/tools/ArrowTool";
import { TextTool } from "../lib/editor/tools/TextTool";
import { EraserTool } from "../lib/editor/tools/EraserTool";
import { RealTextAreaInputControl } from "./editor/tools/RealTextAreaInputControl";
import { EditorContainerProvider } from "../lib/hooks/useEditorContainer";
import { EditorProvider } from "../lib/hooks/useEditor";
import { LineTool } from "../lib/editor/tools/LineTool";
import { applyHistoryToGraphics } from "../lib/editor/applyHistoryToGraphics";
import { useRefWithoutCurrent } from "../lib/hooks/useRefWithoutCurrent";
import { useNavigate } from "react-router-dom";
import { getDrawingLayoutFile } from "../lib/editor/drawingLayouts";
import { base64ToBlob } from "base64-blob";
import { apiUploadDrawingToPatientEncounter } from "../api/resources/patientEncounter/uploadDrawingToPatientEncounter";
import { Modal } from "./common/Modal";
import { LoadingSpinner } from "./common/LoadingSpinner";
import Viewport, { A4, viewport } from "./Viewport";
import { Brush, Locate, Mouse } from "lucide-react";
import './Whiteboard.scss';

type WhiteboardProps = {
  layoutFileName: string;
  patientResourceId: string;
  encounterResourceId: string;
}

export const renderTexture = PixiRenderTexture.create(A4);

export const Whiteboard = ({ layoutFileName, patientResourceId, encounterResourceId }: WhiteboardProps) => {
  // Refs
  const [app, setApp] = useState<PixiApplication>();
  const [pan, setPan] = useState<boolean>(false);

  const navigate = useNavigate()
  const [editorContainer, setEditorContainer] = useState<HTMLDivElement | null>(null);

  const [editorRef, setEditorRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!app || !app.renderer || !editorRef) return;
    const resizeObserver = new ResizeObserver(() => {
      app.renderer.resize(editorRef.clientWidth, editorRef.clientHeight);
      viewport().resize(editorRef.clientWidth, editorRef.clientHeight);
    });
    resizeObserver.observe(editorRef);
    return () => resizeObserver.disconnect();
  }, [app, editorRef]);

  const brushGraphicsRef = useRef<PixiGraphics>(null);
  const maskGraphicsRef = useRef<PixiGraphics>(null);
  const outlineGraphicsRef = useRef<PixiGraphics>(null);
  const pointerGraphicsRef = useRef<PixiGraphics>(null);
  const personOutlineSpriteRef = useRef<PixiSprite>(null);
  const stageRef = useRef<Stage>(null);
  const textGraphicsRef = useRef<PixiGraphics>(null);

  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<'stale' | 'uploading' | 'success' | 'error'>('stale');

  // Original editor instance
  const editor = useRefWithoutCurrent(new Editor(renderTexture));

  useEffect(() => {
    setImageUrl(getDrawingLayoutFile(layoutFileName));
  }, [layoutFileName]);

  useHotkeys(
    [`${Key.Meta}+z`, `${Key.Control}+z`],
    (event) => {
      undoRedoHandler('undo');

      if (!event.repeat) {
        keyUpHandler(event as unknown as KeyboardEvent);
      }
    }
  );

  useHotkeys(
    [`${Key.Meta}+${Key.Shift}+z`, `${Key.Control}+y`],
    (event) => {
      undoRedoHandler('redo');

      if (!event.repeat) {
        keyUpHandler(event as unknown as KeyboardEvent);
      }
    }
  );

  useHotkeys(Key.Escape, (event) => {
    if (!event.repeat) {
      keyUpHandler(event as unknown as KeyboardEvent)
    }
  });

  // States
  const [imageUrl, setImageUrl] = useState<string>(getDrawingLayoutFile(layoutFileName));

  // Handlers
  const keyUpHandler = (event: KeyboardEvent) => {
    useEditorStore.getState().activeTool?.onKeyUp(event);
  }

  const pointerMoveHandler = (event: PointerEvent<HTMLCanvasElement>) => {
    if (pan) {
      return;
    }
    
    const activeTool = useEditorStore.getState().activeTool;

    activeTool?.onPointerMove(event);
    activeTool?.onPointerIndicatorDraw(event);
  }

  const pointerUpHandler = (event: PointerEvent<HTMLCanvasElement>) => {
    if (pan) {
      return;
    }
    
    const activeTool = useEditorStore.getState().activeTool;
    if (!activeTool) {
      return;
    }

    activeTool.onPointerUp(event);
    app!.renderer.render(editor.brushGraphics, { renderTexture, clear: false });
  }

  const pointerDownHandler = (event: PointerEvent<HTMLCanvasElement>) => {
    // Ignore anything except a left-click for mouse peripherals
    if (event.pointerType === 'mouse' && event.button !== 0) {
      return;
    }
    
    if (pan) {
      return;
    }
    
    useEditorStore.getState().activeTool?.onPointerDown(event);
  }

  const brushGraphicsDrawHandler = useCallback((graphics: PixiGraphics) => {
    // Setup editor
    editor.setBrushGraphics(graphics);

    // Setup editor tools
    const pencilTool = new PencilTool(editor);
    pencilTool.activate(); // by default
    const rectangleShapeTool = new RectangleShapeTool(editor);
    const ellipseShapeTool = new EllipseShapeTool(editor);
    const lineTool = new LineTool(editor);
    const arrowTool = new ArrowTool(editor);
    const textTool = new TextTool(editor);
    const eraserTool = new EraserTool(editor, app);

    editor.setTools([pencilTool, rectangleShapeTool, ellipseShapeTool, lineTool, arrowTool, textTool, eraserTool]);

    useEditorStore.setState({
      tools: editor.tools,
      activeTool: editor.activeTool
    });
  }, [editor, app]);

  const outlineGraphicsDrawHandler = useCallback((graphics: PixiGraphics) => {
    graphics.beginFill(0xFFFFFF);
    graphics.drawRect(0, 0, A4.width, A4.height);
    graphics.endFill();
  }, []);

  const pointerGraphicsDrawHandler = useCallback((graphics: PixiGraphics) => {
    // Setup editor
    editor.setPointerGraphics(graphics);
  }, [editor]);

  const textGraphicsDrawHandler = useCallback((graphics: PixiGraphics) => {
    // Setup editor
    editor.setTextGraphics(graphics);
  }, [editor]);

  const maskGraphicsDrawHandler = useCallback((graphics: PixiGraphics) => {
    graphics.beginFill('#fff');
    graphics.drawRect(0, 0, A4.width, A4.height);
    graphics.endFill();
  }, []);

  /**
   * Clears the editor's current drawing.
   */
  const clearHandler = useCallback(() => {
    const editorState = useEditorStore.getState();

    if (!editorState.canUndo()) {
      return;
    }

    // Rest the current point in history to beginning.
    editorState.clearHistory();

    // Clear all brush and text graphics from the canvas.
    editor.brushGraphics.clear();
    app!.renderer.render(editor.brushGraphics, { renderTexture, clear: true });

    if (editor.textGraphics) {
      editor.textGraphics.removeChildren();
      editor.textGraphics.clear();
    }
  }, [editor, app]);

  const undoRedoHandler = useCallback((mode: 'undo' | 'redo') => {
    const editorState = useEditorStore.getState();

    if (mode === 'undo' && !editorState.canUndo()) {
      // Trying to undo, but there's nothing to undo.
      return;
    } else if (mode === 'redo' && !editorState.canRedo()) {
      // Trying to redo, but there's nothing to redo.
      return;
    }

    const newHistory = mode === 'undo'
      ? editorState.undoLatestActionFromHistory()
      : editorState.redoLatestActionFromHistory();

    // Re-render graphics up until the current point in history
    editor.textGraphics?.removeChildren();
    editor.textGraphics?.clear();

    editor.brushGraphics.clear();
    app!.renderer.render(editor.brushGraphics, { renderTexture, clear: true });

    applyHistoryToGraphics(
      newHistory,
      editor.brushGraphics,
      editor.textGraphics,
      () => {
        app!.renderer.render(editor.brushGraphics, { renderTexture, clear: false });
      },
    );

  }, [editor, app]);

  const onExportAndSave = async () => {
    if (!app || !editor.textGraphics) {
      return;
    }

    setUploadStatus('uploading');

    const finalTexture = PixiRenderTexture.create(A4);
    const finalBrushGraphics = new PixiGraphics();
    const finalTextGraphics = new PixiGraphics();

    // Apply a white background to the final exporting render
    const finalBackgroundGraphics = new PixiGraphics();
    finalBackgroundGraphics.beginFill(0xFFFFFF);
    finalBackgroundGraphics.drawRect(0, 0, A4.width, A4.height)
    finalBackgroundGraphics.endFill();
    app.renderer.render(finalBackgroundGraphics, { renderTexture: finalTexture, clear: false });

    // Apply the person outline image to the final exporting render
    if (personOutlineSpriteRef.current) {
      app.renderer.render(personOutlineSpriteRef.current, { renderTexture: finalTexture, clear: false });
    }

    // Apply any brush and text to the final exporting redner
    applyHistoryToGraphics(
      useEditorStore.getState().history,
      finalBrushGraphics,
      finalTextGraphics,
      () => {
        app!.renderer.render(finalBrushGraphics, { renderTexture: finalTexture, clear: false });
        app!.renderer.render(finalTextGraphics, { renderTexture: finalTexture, clear: false });
      },
    );

    const drawingAsBase64 = await app.renderer.extract.base64(finalTexture, 'image/jpeg', 0.92);
    const drawingAsBlob = await base64ToBlob(drawingAsBase64);

    try {
      await apiUploadDrawingToPatientEncounter({
        encounterResourceId: encounterResourceId,
        patientResourceId: patientResourceId,
        drawingImageBlob: drawingAsBlob
      });
      setUploadStatus('success');

      setTimeout(() => {
        navigate('/');
      }, 2500);

    } catch (error) {
      console.error('Failed to upload the drawing:', error);
      setUploadStatus('error');

    } finally {
      // Cleanup.
      finalTexture.destroy();
    }
  }

  const onExportModalClosed = () => {
    setExportModalOpen(false);
    setUploadStatus('stale');
  }

  /**
   * Resets the pan and zoom values to their original settings.
   */
  const resetHandler = () => {
    const instance = viewport();
    const wRatio = A4.width / instance.screenWidth;
    const hRatio = A4.height / instance.screenHeight;
    
    // Only include one or the other to preserve aspect-ratio.
    instance.snapZoom({
      ...(wRatio >= hRatio && { width: instance.screenWidth * wRatio }),
      ...(hRatio >= wRatio && { height: instance.screenHeight * hRatio }),
      removeOnInterrupt: true,
      removeOnComplete: true,
    });
    instance.snap(A4.width / 2, A4.height / 2, {
      removeOnInterrupt: true,
      removeOnComplete: true,
    });
  }

  const mouseHandler = (event: MouseEvent<SVGSVGElement>) => {
    setPan(true);
  }

  const brushHandler = (event: MouseEvent<SVGSVGElement>) => {
    setPan(false);
  }

  return (
    <div ref={setEditorContainer} className="flex">
      {editorContainer ? (
        <EditorContainerProvider container={editorContainer}>
          <EditorProvider editor={editor}>
            <div className="whiteboard__editor" ref={setEditorRef}>
              {editorRef && (
                <>
                  <Stage
                    ref={stageRef}
                    width={editorRef.clientWidth}
                    height={editorRef.clientHeight}
                    onMount={setApp}
                    options={{
                      antialias: true,
                      backgroundColor: 0xCCCCCC,
                      powerPreference: 'default',
                      resizeTo: editorRef,
                      useContextAlpha: false,
                    }}
                    onPointerUp={pointerUpHandler}
                    onPointerDown={pointerDownHandler}
                    onPointerMove={pointerMoveHandler}
                  >
                    <Viewport 
                      active={pan}
                      width={editorRef.clientWidth}
                      height={editorRef.clientHeight}
                    >
                      <Container mask={maskGraphicsRef.current}>
                        <Graphics ref={outlineGraphicsRef} draw={outlineGraphicsDrawHandler} />

                        <Sprite
                          ref={personOutlineSpriteRef}
                          image={imageUrl}
                          scale={{ x: 4, y: 4 }}
                          interactive={false}
                          interactiveChildren={false}
                          anchor={0.5}
                          x={A4.width / 2}
                          y={A4.height / 2}
                        />

                        <Sprite texture={renderTexture} />

                        <Graphics ref={pointerGraphicsRef} draw={pointerGraphicsDrawHandler} />
                        <Graphics ref={brushGraphicsRef} draw={brushGraphicsDrawHandler} />
                        <Graphics ref={textGraphicsRef} draw={textGraphicsDrawHandler} />
                        <Graphics ref={maskGraphicsRef} draw={maskGraphicsDrawHandler} />
                      </Container>
                    </Viewport>
                  </Stage>

                  <RealTextAreaInputControl />
                </>
              )}
            </div>

            <Toolbar
              onClear={clearHandler}
              onRedo={() => undoRedoHandler('redo')}
              onUndo={() => undoRedoHandler('undo')}
              onSave={() => setExportModalOpen(true)}
            />

            <div className="whiteboard__options">
              <Locate onClick={resetHandler} className={`whiteboard__options__icon`} />
              <Mouse onClick={mouseHandler} className={`whiteboard__options__icon ${pan ? 'whiteboard__options__icon--active' : ''}`} />
              <Brush onClick={brushHandler} className={`whiteboard__options__icon ${!pan ? 'whiteboard__options__icon--active' : ''}`} />
            </div>
          </EditorProvider>
        </EditorContainerProvider>
      ) : null}

      <Modal
        open={exportModalOpen}
        onClose={onExportModalClosed}
        primaryAction={{
          label: 'Save',
          onClick: onExportAndSave,
          disabled: uploadStatus === 'uploading' || uploadStatus === 'success'
        }}
        canClose={uploadStatus !== 'uploading' && uploadStatus !== 'success'}
      >
        {uploadStatus === 'success' ? <h2>Your drawing was saved!</h2>
          : uploadStatus === 'error' ? <h2>Something went wrong.</h2>
          : <h2>Do you wish to save this image?</h2>}

        {uploadStatus === 'uploading'
          ? <LoadingSpinner aria-label="Uploading" label="Uploading..." />
          : null}

        {uploadStatus === 'error'
          ? <p>Your image could not be saved. Please try again.</p>
          : null}
      </Modal>
    </div>
  )
}
