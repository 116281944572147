import { Button, DateInput, Details, Radios } from "nhsuk-react-components"
import { format } from "date-fns";
import { useState } from "react";
import { NhsDateInputValue, padWithLeadingZero, parseDateAsNhsDateInputValue, parseNhsDateInputValueAsDate } from "../../lib/common/nhsDateInputValue";
import './DateFilter.scss';

type DateFilterProps = {
  label: string;
  appliedDate: Date | undefined;
  onApply: (newAppliedDate: Date | null) => void;
  canApplyIfInputIsValid: boolean;
}

/**
 * The `none` date filter type indicates that no filter was applied.
 * The `specified` date filter type indicates that a date filter has been applied.
 */
type DateFilterType = 'none' | 'specified';

export const DateFilter = ({ label, appliedDate, canApplyIfInputIsValid, onApply }: DateFilterProps) => {
  const [selectedFilterType, setFilterType] = useState<DateFilterType>(
    appliedDate === undefined ? 'none' : 'specified'
  );
  const [enteredValue, setEnteredValue] = useState<NhsDateInputValue>(
    parseDateAsNhsDateInputValue(appliedDate)
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const getFormattedAppliedDate = (): string =>{
    if (!appliedDate) {
      return 'Not set.';
    }

    // Formats to something like: "29th November 2023"
    return format(appliedDate, 'do MMMM yyyy');
  }

  const onExpansionToggle = (): void => {
    const isExpanding = !isExpanded;
    setIsExpanded(isExpanding);

    if (!isExpanding) {
      // When collapsing, revert this filter component's state to default.
      setFilterType(getAppliedDateFilterType());
      setEnteredValue(getAppliedDateParsedAsNhsDateInputValue());
    }
  }

  const onFilterTypeChanged = (type: DateFilterType) => {
    setFilterType(type);

    const changedToSpecifiedFilterType = selectedFilterType !== 'specified' && type === 'specified';
    if (changedToSpecifiedFilterType) {
      // Reset the entered value input so it's the most up-to-date
      setEnteredValue(getAppliedDateParsedAsNhsDateInputValue());
    }
  }

  const getAppliedDateFilterType = (): DateFilterType => {
    return appliedDate === undefined ? 'none' : 'specified';
  }

  const getAppliedDateParsedAsNhsDateInputValue = (): NhsDateInputValue => {
    return parseDateAsNhsDateInputValue(appliedDate);
  }

  const onValueChange = (nhsDateInputValue: NhsDateInputValue): void => {
    setEnteredValue(nhsDateInputValue);
  }

  const didChange = (): boolean => {
    const filterTypeChanged = selectedFilterType !== getAppliedDateFilterType();
    if (filterTypeChanged) {
      return true;
    }

    const appliedDateAsNhsDateInputValue = getAppliedDateParsedAsNhsDateInputValue();
    const enteredDateHasChanged = (
      padWithLeadingZero(enteredValue.day) !== appliedDateAsNhsDateInputValue.day
      || padWithLeadingZero(enteredValue.month) !== appliedDateAsNhsDateInputValue.month
      || enteredValue.year !== appliedDateAsNhsDateInputValue.year
    );

    return filterTypeChanged || (selectedFilterType === 'specified' && enteredDateHasChanged);
  }

  const canApply = (): boolean => {
    let isValidSpecificDateIfApplicable = true;
    if (selectedFilterType === 'specified') {
      isValidSpecificDateIfApplicable = !!parseNhsDateInputValueAsDate(enteredValue);
    }

    const valuesHaveChanged = didChange();
    return isValidSpecificDateIfApplicable && canApplyIfInputIsValid && valuesHaveChanged;
  }

  const applyHandler = (): void => {
    if (selectedFilterType === 'none') {
      onApply(null);
      return;
    }

    const date = parseNhsDateInputValueAsDate(enteredValue);
    if (!date) {
      // Invalid date.
      return;
    }

    onApply(date);
  }

  return (
    <Details className="date-filter">
      <Details.Summary className="date-filter--summary" onClick={onExpansionToggle}>
        <div className="date-filter--label">{label}</div>

        {!isExpanded ? (
          <div className="date-filter--applied-value">{getFormattedAppliedDate()}</div>
        ) : null}
      </Details.Summary>

      <Details.Text open={isExpanded}>
        <Radios value={selectedFilterType}>
          <Radios.Radio
            onClick={() => onFilterTypeChanged('none')}
            value="any"
            checked={selectedFilterType === 'none'}
          >
            On any date.
          </Radios.Radio>
          <Radios.Radio
            onClick={() => onFilterTypeChanged('specified')}
            value="specified"
            checked={selectedFilterType === 'specified'}
            conditional={
              <DateInput
                value={enteredValue}
                autoSelectNext
                onChange={(event) => onValueChange(event.currentTarget.value)}
              />
            }
          >
            On a specific date{selectedFilterType === 'specified' ? ':' : '.'}
          </Radios.Radio>
        </Radios>

        <Button onClick={applyHandler} disabled={!canApply()}>
          Apply
        </Button>
      </Details.Text>
    </Details>
  )
}
