import { Fieldset } from "nhsuk-react-components"
import { ModifiablePenToolProperties, PenTool } from "../../../../lib/editor/tools/PenTool"
import { ButtonGroup } from "../../../common/ButtonGroup"
import { useCallback } from "react"
import { useEditor } from "../../../../lib/hooks/useEditor"

type PenToolControllerProps = {
  penTool: PenTool
}

export const PenToolController = ({ penTool }: PenToolControllerProps) => {
  const editor = useEditor();

  const onStrokeColorSelect = useCallback((strokeColor: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiablePenToolProperties>>({
      strokeColor
    });
  }, [editor]);

  const onStrokeSizeSelect = useCallback((strokeSize: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiablePenToolProperties>>({
      strokeSize: parseInt(strokeSize)
    });
  }, [editor]);

  return (
    <>
      {penTool.controlMap.strokeColor.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Border color:</Fieldset.Legend>

          <ButtonGroup
            variant="color"
            onSelect={onStrokeColorSelect}
            selectedOptionValue={penTool.properties.strokeColor}
            options={penTool.controlMap.strokeColor.options.map((strokeColorOption) => ({
              name: strokeColorOption.label,
              value: strokeColorOption.value
            }))}
          />
        </Fieldset>
      ) : null}

      {penTool.controlMap.strokeSize.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Border size:</Fieldset.Legend>

          <ButtonGroup
            variant="size"
            onSelect={onStrokeSizeSelect}
            selectedOptionValue={penTool.properties.strokeSize.toString()}
            options={penTool.controlMap.strokeSize.options.map((strokeSizeOption) => ({
              name: strokeSizeOption.label,
              value: strokeSizeOption.value,
              color: editor.store.activeTool?.properties.strokeColor
            }))}
          />
        </Fieldset>
      ) : null}
    </>
  )
}
