type DrawingLayout = {
  /**
   * The human-readable name of the layout.
   */
  name: string;
  /**
   * The name of the layout file stored at {@link PUBLIC_STORAGE_PATH_FOR_DRAWING_LAYOUTS}
   * - including file extension.
   */
  fileName: string;
}

const PUBLIC_STORAGE_PATH_FOR_DRAWING_LAYOUTS = '/assets/images/layouts'

/**
 * Lists the layouts available at ./Client/public/assets/layouts
 */
export const getDrawingLayouts = () => {
  return [
    { name: 'Body Back', fileName: 'body-back.png' },
    { name: 'Body Front', fileName: 'body-front.png' },
    { name: 'Body Map Full', fileName: 'body-map-full.png' },
    { name: 'Elbow AP', fileName: 'elbow-ap.png' },
    { name: 'Elbow Bent', fileName: 'elbow-bent.png' },
    { name: 'Elbow Lateral', fileName: 'elbow-lateral.png' },
    { name: 'Elbow PA', fileName: 'elbow-pa.png' },
    { name: 'Feet', fileName: 'feet.png' },
    { name: 'Hand Dorsal Bones', fileName: 'hand-dorsal-bones.png' },
    { name: 'Hand Palmar Bones', fileName: 'hand-palmar-bones.png' },
    { name: 'Knee Lateral', fileName: 'knee-lateral.png' },
    { name: 'Man AP', fileName: 'man-ap.png' },
    { name: 'Man Left Lateral', fileName: 'man-left-lateral.png' },
    { name: 'Man PA', fileName: 'man-pa.png' },
    { name: 'Man Right Lateral', fileName: 'man-right-lateral.png' },
    { name: 'Man Torso AP', fileName: 'man-torso-ap.png' },
    { name: 'Man Upper Body AP', fileName: 'man-upper-body-ap.png' },
    { name: 'Man Upper Body PA', fileName: 'man-upper-body-pa.png' },
    { name: 'Newborn AP', fileName: 'newborn-ap.png' },
    { name: 'Woman AP', fileName: 'woman-ap.png' },
    { name: 'Woman Left Lateral', fileName: 'woman-left-lateral.png' },
    { name: 'Woman PA', fileName: 'woman-pa.png' },
    { name: 'Woman Right Lateral', fileName: 'woman-right-lateral.png' },
    { name: 'Woman Upper Body AP', fileName: 'woman-upper-body-ap.png' },
    { name: 'Woman Upper Body PA', fileName: 'woman-upper-body-pa.png' },
  ]
}

export const getDrawingLayoutFile = (fileName: DrawingLayout['fileName']) => {
  return `${PUBLIC_STORAGE_PATH_FOR_DRAWING_LAYOUTS}/${fileName}`;
}
