import { Fieldset } from "nhsuk-react-components"
import { ModifiablePencilToolProperties, PencilTool } from "../../../../lib/editor/tools/PencilTool"
import { ButtonGroup } from "../../../common/ButtonGroup"
import { useCallback } from "react"
import { useEditor } from "../../../../lib/hooks/useEditor"

type PencilToolControllerProps = {
  pencilTool: PencilTool
}

export const PencilToolController = ({ pencilTool }: PencilToolControllerProps) => {
  const editor = useEditor();

  const onBrushColorSelect = useCallback((brushColor: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiablePencilToolProperties>>({
      brushColor
    });
  }, [editor]);

  const onBrushSizeSelect = useCallback((brushSize: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiablePencilToolProperties>>({
      brushSize: parseInt(brushSize)
    });
  }, [editor]);

  return (
    <>
      {pencilTool.controlMap.brushColor.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Brush color:</Fieldset.Legend>

          <ButtonGroup
            variant="color"
            onSelect={onBrushColorSelect}
            selectedOptionValue={pencilTool?.properties.brushColor}
            options={pencilTool.controlMap.brushColor.options.map((brushColorOption) => ({
              name: brushColorOption.label,
              value: brushColorOption.value
            }))}
          />
        </Fieldset>
      ) : null}

      {pencilTool.controlMap.brushSize.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Brush size:</Fieldset.Legend>

          <ButtonGroup
            variant="size"
            onSelect={onBrushSizeSelect}
            selectedOptionValue={pencilTool?.properties.brushSize.toString()}
            options={pencilTool.controlMap.brushSize.options.map((brushSizeOption) => ({
              name: brushSizeOption.label,
              value: brushSizeOption.value,
              color: editor.store.activeTool?.properties.brushColor
            }))}
          />
        </Fieldset>
      ) : null}
    </>
  )
}
