import { KeyboardEvent, PointerEvent } from "react";
import { Tool } from "./Tool";
import type { BaseToolAction, ToolCursorType } from "./Tool.types";
import { Point } from "../../geometry/Point";
import { ToolControlMap } from "./ToolControl.types";
import { LINE_CAP, LINE_JOIN, Graphics as PixiGraphics } from "pixi.js";
import { Key } from "ts-key-enum";
import { degreesToRadians } from "../../geometry/degreesToRadians";
import cloneDeep from "lodash.clonedeep";
import { pointerCoordinateFromEvent } from "../pointerCoordinateFromEvent";


export type ArrowToolId = 'arrow';

/**
 * The position where the arrow head will be drawn.
 *
 * - `start`: At the start of the arrow line (first point)
 * - `end`: At the end of the arrow line (second point)
 * - `both`: At the start and end of the arrow line. Bi-directional arrow.
 */
export type ArrowHeadPosition = 'start' | 'end' | 'both';

export type ArrowToolProperties = {
  arrowHeadAngle: number;
  arrowHeadPosition: ArrowHeadPosition;
  strokeColor: string;
  strokeSize: number;
}

export type ModifiableArrowToolProperties = Pick<ArrowToolProperties, 'strokeColor' | 'strokeSize' | 'arrowHeadPosition'>;

export interface ArrowToolAction extends BaseToolAction {
  toolId: ArrowToolId;
  values: {
    points: Point[];
    properties: ArrowToolProperties;
  }
}

export class ArrowTool extends Tool<ArrowToolProperties, ModifiableArrowToolProperties> {
  /**
   * The minimum distance between two points, for the drawing to be considered.
   */
  static POINTS_DISTANCE_THRESHOLD: number = 2;

  override id: ArrowToolId = 'arrow';
  override type = 'shape';
  override name = 'Arrow';
  override cursor: ToolCursorType = 'crosshair';

  override properties: ArrowToolProperties = {
    arrowHeadAngle: degreesToRadians(30),
    arrowHeadPosition: 'end',
    strokeColor: 'red',
    strokeSize: 4
  }

  override controlMap: ToolControlMap<ModifiableArrowToolProperties> = {
    strokeColor: {
      type: "choices",
      options: [
        { label: 'Red', value: 'red' },
        { label: 'Green', value: 'green' },
        { label: 'Blue', value: 'blue' },
        { label: 'Orange', value: 'orange' },
      ]
    },
    strokeSize: {
      type:  'choices',
      options: [
        { label: 'S', value: '4' },
        { label: 'M', value: '8' },
        { label: 'L', value: '12' },
        { label: 'XL', value: '16' },
      ]
    },
    arrowHeadPosition: {
      type: 'choices',
      options: [
        { label: 'Start', value: 'start' },
        { label: 'End', value: 'end' },
        { label: 'Both', value: 'both' },
      ]
    }
  }

  override propertySetters: SetterFns<ModifiableArrowToolProperties> = {
    setArrowHeadPosition: (arrowHeadPosition) => {
      this.properties.arrowHeadPosition = arrowHeadPosition;
    },

    setStrokeColor: (strokeColor) => {
      this.properties.strokeColor = strokeColor;
    },

    setStrokeSize: (strokeSize) => {
      this.properties.strokeSize = strokeSize;
    }
  };

  isDrawing: boolean = false;
  isPressing: boolean = false;
  points: Point[] = [];

  onKeyUp(event: KeyboardEvent<HTMLCanvasElement>): void {
    this.isDrawing = false;
    this.points = [];

    if (event.key === Key.Escape) {
      this.editor.brushGraphics.clear();
    }
  }

  onPointerMove(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.brushGraphics) {
      return;
    }

    if (!this.points.length) {
      return;
    }

    if (this.isPressing && !this.isDrawing) {
      this.isDrawing = true;
    }

    if (this.isDrawing) {
      this.points[1] = pointerCoordinateFromEvent(event);

      ArrowTool.draw(this.editor.brushGraphics, this.points, this.properties);
    }
  }

  onPointerUp(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = false;

    if (this.points.length === 2) {
      this.isDrawing = false;

      const enoughDrawThreshold = ArrowTool.enoughDrawThreshold(this.points);
      if (enoughDrawThreshold) {
        this.editor.store.appendActionToHistory(
          this.recordAction({
            points: this.points,
            properties: this.properties
          })
        );
      }

      this.points = [];
      return;
    }

    if (!this.points.length) {
      this.points[0] = pointerCoordinateFromEvent(event);
    }

    this.isDrawing = true;
  }

  onPointerDown(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.points.length) {
      this.points[0] = pointerCoordinateFromEvent(event);
    }

    this.isPressing = true;
    this.isDrawing = false;
  }

  onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.pointerGraphics) {
      return;
    }

    this.editor.pointerGraphics.clear();
    this.editor.pointerGraphics.beginFill(this.properties.strokeColor);

    const { x, y } = pointerCoordinateFromEvent(event);
    this.editor.pointerGraphics.drawCircle(x, y, this.properties.strokeSize / 2);
  }

  static draw(graphics: PixiGraphics, points: Point[], properties: ArrowToolProperties) {
    const startPoint = points[0];
    const endPoint = points[1];

    const arrowHeadLength = properties.strokeSize * 5;
    const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);

    graphics.clear();

    const enoughDrawThreshold = this.enoughDrawThreshold(points);
    if (!enoughDrawThreshold) {
      return;
    }

    // Draw the line
    graphics.lineStyle({
      width: properties.strokeSize,
      color: properties.strokeColor,
      cap: LINE_CAP.BUTT,
      join: LINE_JOIN.ROUND
    });

    const offsetX = arrowHeadLength * Math.cos(angle) / 2;
    const offsetY = arrowHeadLength * Math.sin(angle) / 2;

    if (properties.arrowHeadPosition === 'start') {
      // Inset the line from the start, so it doesn't extrude from the arrow head point.
      graphics.moveTo(startPoint.x + offsetX, startPoint.y + offsetY);
      graphics.lineTo(endPoint.x, endPoint.y);

    } else if (properties.arrowHeadPosition === 'end') {
      // Inset the line from the end, so it doesn't extrude from the arrow head point.
      graphics.moveTo(startPoint.x, startPoint.y);
      graphics.lineTo(endPoint.x - offsetX, endPoint.y - offsetY);

    } else if (properties.arrowHeadPosition === 'both') {
      // Inset the line from the start and end, so it doesn't extrude from both of the arrow head points.
      graphics.moveTo(startPoint.x + offsetX, startPoint.y + offsetY);
      graphics.lineTo(endPoint.x - offsetX, endPoint.y - offsetY);
    }

    if (properties.arrowHeadPosition === 'start' || properties.arrowHeadPosition === 'both') {
      // Draw the arrow at the start of the line
      graphics.lineStyle({
        width: 1,
        color: properties.strokeColor,
        cap: LINE_CAP.SQUARE,
        join: LINE_JOIN.ROUND
      });
      graphics.beginFill(properties.strokeColor);
      graphics.moveTo(startPoint.x, startPoint.y); // Use startPoint instead of nextPoint
      graphics.lineTo(
        startPoint.x + arrowHeadLength * Math.cos(angle - properties.arrowHeadAngle),
        startPoint.y + arrowHeadLength * Math.sin(angle - properties.arrowHeadAngle)
      );
      graphics.lineTo(
        startPoint.x + arrowHeadLength * Math.cos(angle + properties.arrowHeadAngle),
        startPoint.y + arrowHeadLength * Math.sin(angle + properties.arrowHeadAngle)
      );
      graphics.endFill();
    }

    if (properties.arrowHeadPosition === 'end' || properties.arrowHeadPosition === 'both') {
      // Draw the arrow at the end of the line
      graphics.lineStyle({
        width: 1,
        color: properties.strokeColor,
        cap: LINE_CAP.SQUARE,
        join: LINE_JOIN.ROUND
      });
      graphics.beginFill(properties.strokeColor);
      graphics.moveTo(endPoint.x, endPoint.y);
      graphics.lineTo(
        endPoint.x - arrowHeadLength * Math.cos(angle - properties.arrowHeadAngle),
        endPoint.y - arrowHeadLength * Math.sin(angle - properties.arrowHeadAngle)
      );
      graphics.lineTo(
        endPoint.x - arrowHeadLength * Math.cos(angle + properties.arrowHeadAngle),
        endPoint.y - arrowHeadLength * Math.sin(angle + properties.arrowHeadAngle)
      );
      graphics.endFill();
    }
  }

  static enoughDrawThreshold(points: Point[]): boolean {
    if (points.length < 2) {
      // Not enough points
      return false;
    }

    const distanceX = Math.abs(points[1].x - points[0].x);
    const distanceY = Math.abs(points[1].y - points[0].y);
    return distanceX > this.POINTS_DISTANCE_THRESHOLD || distanceY > this.POINTS_DISTANCE_THRESHOLD;
  }

  private recordAction(values: ArrowToolAction['values']): ArrowToolAction {
    return {
      toolId: this.id,
      values: cloneDeep(values)
    }
  }
}

/**
 * A guard to check whether or not the given {@link tool} is a {@link ArrowTool}.
 *
 * @param tool - The tool to check.
 * @returns `true` if {@link tool} is a {@link ArrowTool}, otherwise `false`.
 */
export const isArrowTool = (tool: Pick<Tool, 'id'>): tool is ArrowTool => {
  return tool.id === 'arrow';
}
