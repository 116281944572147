import axios from "axios";
import {applyAccessTokenToRequest} from "./interceptors/request/applyAccessTokenToRequest";
import { reportApiErrorsFromApiResponse } from "./interceptors/response/reportApiErrorsFromApiResponse";

const apiClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

if (process.env.NODE_ENV !== 'test') {
  // Request interceptors
  apiClient.interceptors.request.use(
    applyAccessTokenToRequest,
    Promise.reject
  );

  apiClient.interceptors.response.use(
    (response) => response, // No interceptors to handle succesful API reponse at this moment. Keep default behaviour.
    reportApiErrorsFromApiResponse // Intercept the error responses.
  );
}

export { apiClient };
