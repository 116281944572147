import { RedirectRequest } from '@azure/msal-browser'

export const msalLoginRequest: RedirectRequest = {
  scopes: [
    'User.Read',
    'Group.Read.All',
    'User.ReadBasic.All',
    'profile',
    'openid',
    'email'
  ],
}
