import { Point } from "./Point";

export type BoundingBox = {
  x1: number,
  y1: number,
  x2: number,
  y2: number,
}

/**
 * Calculate the bounding volume for an arbitrary number of points.
 * 
 * @param points A list of Point objects, at (X,Y).
 * @returns A BoundingBox object, from (X1,Y1) to (X2,Y2).
 */
export const getBoundingBoxFromPoints = (points: Point[]) => {
  return points.reduce((acc, cur) => {
    return {
      x1: Math.min(acc.x1, cur.x),
      y1: Math.min(acc.y1, cur.y),
      x2: Math.max(acc.x2, cur.x),
      y2: Math.max(acc.y2, cur.y)
    }
  }, {
    x1: Number.MAX_SAFE_INTEGER,
    y1: Number.MAX_SAFE_INTEGER,
    x2: Number.MIN_SAFE_INTEGER,
    y2: Number.MIN_SAFE_INTEGER
  });
}

/**
 * Determine if two bounding boxes overlap.
 * 
 * @param a A BoundingBox object.
 * @param b A BoundingBox object.
 * @returns true if overlapping, otherwise false.
 */
export const hasOverlap = (a: BoundingBox, b: BoundingBox) => {
  if (a.x2 <= b.x1 || b.x2 <= a.x1)
  {
    return false; // No overlap on X-axis.
  }

  if (a.y2 <= b.y1 || b.y2 <= a.y1)
  {
    return false; // No overlap on Y-axis.
  }
  
  return true;
}

/**
 * Determine if Line A->B intersects with Line C->D
 * @param a The start of Line A->B
 * @param b The end of Line A->B
 * @param c The start of Line C->D
 * @param d The end of Line C->D
 * @returns 
 */
export const hasIntersect = (a: Point, b: Point, c: Point, d: Point) => {
  const determinant = (b.x - a.x) * (d.y - c.y) - (d.x - c.x) * (b.y - a.y);
  if (determinant === 0) {
    return false;
  }
  const lambda = ((d.y - c.y) * (d.x - a.x) + (c.x - d.x) * (d.y - a.y)) / determinant;
  const gamma = ((a.y - b.y) * (d.x - a.x) + (b.x - a.x) * (d.y - a.y)) / determinant;
  return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
}

/**
 * Determine if Point A falls within BoundingBox B.
 * 
 * @param a A Point object.
 * @param b A BoundingBox object.
 * @returns true if overlapping, otherwise false.
 */
export const hasIntersectWithRect = (a: Point, b: BoundingBox) => {
  if (a.x <= b.x1 || b.x2 <= a.x) {
    return false; // No overlap on X-axis.
  }

  if (a.y <= b.y1 || b.y2 <= a.y) {
    return false; // No overlap on Y-axis.
  }

  return true;
}

/**
 * Determine if Point A falls within Ellipse B->C
 * @param a A Point object.
 * @param b A Point object. Center of Ellipse.
 * @param c A Point object. Radius of Ellipse.
 * @returns true if overlapping, otherwise false.
 */
export const hasIntersectWithEllipse = (a: Point, b: Point, c: Point) => {
  const x = ((a.x - b.x) ** 2) / ((c.x - b.x) ** 2);
  const y = ((a.y - b.y) ** 2) / ((c.y - b.y) ** 2);
  return (x + y) <= 1;
}
