import { Fieldset } from "nhsuk-react-components"
import { ModifiableEllipseShapeToolProperties, EllipseShapeTool } from "../../../../lib/editor/tools/EllipseShapeTool"
import { ButtonGroup } from "../../../common/ButtonGroup"
import { useCallback } from "react"
import { useEditor } from "../../../../lib/hooks/useEditor"

type EllipseShapeToolControllerProps = {
  ellipseShapeTool: EllipseShapeTool
}

export const EllipseShapeToolController = ({ ellipseShapeTool }: EllipseShapeToolControllerProps) => {
  const editor = useEditor();

  const onStrokeColorSelect = useCallback((strokeColor: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableEllipseShapeToolProperties>>({
      strokeColor
    });
  }, [editor]);

  const onFillColorSelect = useCallback((fillColor: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableEllipseShapeToolProperties>>({
      fillColor
    });
  }, [editor]);

  const onStrokeSizeSelect = useCallback((strokeSize: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableEllipseShapeToolProperties>>({
      strokeSize: parseInt(strokeSize)
    });
  }, [editor]);

  return (
    <>
      {ellipseShapeTool.controlMap.strokeColor.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Border color:</Fieldset.Legend>

          <ButtonGroup
            variant="color"
            onSelect={onStrokeColorSelect}
            selectedOptionValue={ellipseShapeTool.properties.strokeColor}
            options={ellipseShapeTool.controlMap.strokeColor.options.map((strokeColorOption) => ({
              name: strokeColorOption.label,
              value: strokeColorOption.value
            }))}
          />
        </Fieldset>
      ) : null}

      {ellipseShapeTool.controlMap.fillColor.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Background color:</Fieldset.Legend>

          <ButtonGroup
            variant="color"
            onSelect={onFillColorSelect}
            selectedOptionValue={ellipseShapeTool.properties.fillColor}
            options={ellipseShapeTool.controlMap.fillColor.options.map((fillColorOption) => ({
              name: fillColorOption.label,
              value: fillColorOption.value
            }))}
          />
        </Fieldset>
      ) : null}

      {ellipseShapeTool.controlMap.strokeSize.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Border size:</Fieldset.Legend>

          <ButtonGroup
            variant="size"
            onSelect={onStrokeSizeSelect}
            selectedOptionValue={ellipseShapeTool.properties.strokeSize.toString()}
            options={ellipseShapeTool.controlMap.strokeSize.options.map((strokeSizeOption) => ({
              name: strokeSizeOption.label,
              value: strokeSizeOption.value,
              color: editor.store.activeTool?.properties.strokeColor
            }))}
          />
        </Fieldset>
      ) : null}
    </>
  )
}
