import { useAuth } from '../../lib/hooks/useAuth';
import { useMsal } from '@azure/msal-react';
import { UserCircle } from 'lucide-react';
import { AnchorButton } from '../common/AnchorButton';
import './MyAccount.scss';

export const MyAccount = () => {
  const me = useAuth();
  const { instance } = useMsal();

  const onLogout = async () => {
    if (!me.authenticated) {
      return;
    }

    await instance.logoutRedirect({ account: me.info, postLogoutRedirectUri: '/' });
  }

  return (
    <div className='my-account my-account__user'>
      <UserCircle className='my-account__user__icon' size={26} />

      <div>
        <div>
          <p className="my-account__user__detail">{me.authenticated ? me.info.name : '...'}</p>
          <p className="my-account__user__detail identifier">{me.authenticated ? me.info.username : null}</p>
        </div>

        {me.authenticated ? (
          <AnchorButton reverse onClick={onLogout}>
            Logout
          </AnchorButton>
        ) : null}
      </div>
    </div>
  )
}
