import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from "react";
import { Textarea, TextAreaProps } from "@nextui-org/input";
import { useTextStore } from "../../../data/text.store";
import { styled } from "styled-components";
import { useEditorStore } from "../../../data/editor.store";
import { ModifiableTextToolProperties } from "../../../lib/editor/tools/TextTool";
import { selectTextToolIfActive } from "../../../data/editor.selectors";
import './RealTextAreaInputControl.scss';
import { useEditor } from "../../../lib/hooks/useEditor";
import { viewport } from "../../Viewport";

export type RealTextAreaInputControlElement = {
  focus: () => void;
}

type RealTextAreaInputControlProps = TextAreaProps & {
}

const StyledTextArea = styled(Textarea)<{ $posX?: number, $posY?: number }>`
  position: absolute;
  left: ${props => props.$posX ?? 0}px;
  top: ${props => props.$posY ?? 0}px;
`;

export const RealTextAreaInputControl = forwardRef<RealTextAreaInputControlElement, RealTextAreaInputControlProps>(
  (props, ref) => {
    const textAreaRef = useRef<HTMLInputElement>(null);
    const [isTyping, point, text, setText] = useTextStore((store) => [store.isTyping, store.pointInDocument, store.text, store.setText, store.stopTypingAndReset]);
    const activeTextTool = useEditorStore(selectTextToolIfActive);
    const editor = useEditor();

    useImperativeHandle(ref, () => {
      return {
        focus
      }
    });

    const focus = () => {
      textAreaRef.current?.focus();
    }

    const textChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      const text = e.currentTarget.value;

      setText(text);
      editor.store.updateActiveToolProps<Partial<ModifiableTextToolProperties>>({ text });
    }

    const focusChangeHandler = (isFocused: boolean) => {
      if (!activeTextTool) {
        return;
      }

      // if (!isFocused) {
      //   stopTypingAndReset();
      //   // updateActiveToolProps<Partial<ModifiableTextToolProperties>>({ text: '' });
      // }

      if (!isFocused) {
        // if (isEmpty(text.trim())) {
        //   // Unfocused and text is empty
        //   return;
        // }

        activeTextTool.placeText();


        // Unfocused, but there were some text on it, place that text.
        // editor.store.updateActiveToolProps<Partial<ModifiableTextToolProperties>>({ text });
      }
    }

    if (!isTyping) {
      return null;
    }

    const scale = viewport().scale.x;

    return (
      <StyledTextArea
        ref={textAreaRef}
        value={text}
        autoFocus={true}
        onFocusChange={focusChangeHandler}
        minRows={1}
        $posX={point?.x}
        $posY={point?.y}
        onChange={textChangeHandler}
        style={{
          fontSize: (activeTextTool?.properties.fontSize ?? 0) * scale,
          color: activeTextTool?.properties.textColor,
          width: `${960 * scale}px`
        }}
        classNames={{
          input: 'real-text-area-input-control'
        }}
      />
    )
  }
)
