import { KeyboardEvent, PointerEvent } from "react";
import { Tool } from "./Tool";
import type { BaseToolAction, ToolCursorType } from "./Tool.types";
import { Point } from "../../geometry/Point";
import { ToolControlMap } from "./ToolControl.types";
import { Graphics as PixiGraphics } from "pixi.js";
import { Key } from "ts-key-enum";
import cloneDeep from "lodash.clonedeep";
import { distanceBetweenPoints } from "../../geometry/distanceBetweenPoints";
import { pointerCoordinateFromEvent } from "../pointerCoordinateFromEvent";

export type LineToolId = 'line';

export type LineToolProperties = {
  strokeColor: string;
  strokeSize: number;
}

export type ModifiableLineToolProperties = Pick<LineToolProperties, 'strokeColor' | 'strokeSize'>;

export interface LineToolAction extends BaseToolAction {
  toolId: LineToolId;
  values: {
    points: Point[];
    properties: LineToolProperties;
  }
}

export class LineTool extends Tool<LineToolProperties, ModifiableLineToolProperties> {
  override id: LineToolId = 'line';
  override type = 'shape';
  override name = 'Line';
  override cursor: ToolCursorType = 'crosshair';

  override properties: LineToolProperties = {
    strokeColor: 'red',
    strokeSize: 4
  }

  override controlMap: ToolControlMap<ModifiableLineToolProperties> = {
    strokeColor: {
      type: "choices",
      options: [
        { label: 'Red', value: 'red' },
        { label: 'Green', value: 'green' },
        { label: 'Blue', value: 'blue' },
        { label: 'Orange', value: 'orange' },
      ]
    },
    strokeSize: {
      type: 'choices',
      options: [
        { label: 'S', value: '4' },
        { label: 'M', value: '8' },
        { label: 'L', value: '12' },
        { label: 'XL', value: '16' },
      ]
    },
  }

  override propertySetters: SetterFns<ModifiableLineToolProperties> = {
    setStrokeColor: (strokeColor) => {
      this.properties.strokeColor = strokeColor;
    },

    setStrokeSize: (strokeSize) => {
      this.properties.strokeSize = strokeSize;
    }
  };

  isDrawing: boolean = false;
  points: Point[] = [];

  onKeyUp(event: KeyboardEvent<HTMLCanvasElement>): void {
    this.isDrawing = false;
    this.points = [];

    if (event.key === Key.Escape) {
      this.editor.brushGraphics.clear();
    }
  }

  onPointerMove(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.isDrawing || !this.editor.brushGraphics || !this.points.length) {
      return;
    }

    const nextPoint = pointerCoordinateFromEvent(event);;

    if (!this.arePointsTooCloseTogether(this.points[0], nextPoint)) {
      this.points[1] = nextPoint;
      LineTool.draw(this.editor.brushGraphics, this.points, this.properties);
    }
  }

  onPointerUp(event: PointerEvent<HTMLCanvasElement>): void {
    if (this.points.length === 2) {
      this.editor.store.appendActionToHistory(
        this.recordAction({
          points: this.points,
          properties: this.properties
        })
      );

      this.points = [];
    }
  }

  onPointerDown(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.points.length) {
      this.points[0] = pointerCoordinateFromEvent(event);

      this.isDrawing = true;
    }
  }

  onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.pointerGraphics) {
      return;
    }

    this.editor.pointerGraphics.clear();
    this.editor.pointerGraphics.beginFill(this.properties.strokeColor);

    const { x, y } = pointerCoordinateFromEvent(event);
    this.editor.pointerGraphics.drawCircle(x, y, this.properties.strokeSize / 2);
  }

  static draw(graphics: PixiGraphics, points: Point[], properties: LineToolProperties) {
    const previousPoint = points[0];
    const nextPoint = points[1];

    graphics.clear();
    graphics.lineStyle({
      width: properties.strokeSize,
      color: properties.strokeColor
    });

    graphics.moveTo(previousPoint.x, previousPoint.y);
    graphics.lineTo(nextPoint.x, nextPoint.y);
  }

  private arePointsTooCloseTogether = (pointA: Point, pointB: Point): boolean => {
    const THRESHOLD = 5; // px

    const distance = distanceBetweenPoints(pointA, pointB);
    if (distance < THRESHOLD) {
      return true;
    }

    return false;
  }

  private recordAction(values: LineToolAction['values']): LineToolAction {
    return {
      toolId: this.id,
      values: cloneDeep(values)
    }
  }
}

/**
 * A guard to check whether or not the given {@link tool} is a {@link LineTool}.
 *
 * @param tool - The tool to check.
 * @returns `true` if {@link tool} is a {@link LineTool}, otherwise `false`.
 */
export const isLineTool = (tool: Pick<Tool, 'id'>): tool is LineTool => {
  return tool.id === 'line';
}
