import { useCallback } from "react"
import { Fieldset } from "nhsuk-react-components"
import { ModifiableTextToolProperties, TextTool } from "../../../../lib/editor/tools/TextTool"
import { ButtonGroup } from "../../../common/ButtonGroup"
import { useEditor } from "../../../../lib/hooks/useEditor"

type TextToolControllerProps = {
  textTool: TextTool
}

export const TextToolController = ({ textTool }: TextToolControllerProps) => {
  const editor = useEditor();

  const onFontSizeSelect = useCallback((fontSize: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableTextToolProperties>>({
      fontSize: parseInt(fontSize)
    });
  }, [editor]);

  const onTextColorSelect = useCallback((textColor: string) => {
    editor.store.updateActiveToolProps<Partial<ModifiableTextToolProperties>>({
      textColor
    });
  }, [editor]);

  return (
    <>
      {textTool.controlMap.textColor.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Text color:</Fieldset.Legend>

          <ButtonGroup
            variant="color"
            onSelect={onTextColorSelect}
            selectedOptionValue={textTool.properties.textColor.toString()}
            options={textTool.controlMap.textColor.options.map((textColorOption) => ({
              name: textColorOption.label,
              value: textColorOption.value
            }))}
          />
        </Fieldset>
      ) : null}

      {textTool.controlMap.fontSize.type === 'choices' ? (
        <Fieldset>
          <Fieldset.Legend>Font size:</Fieldset.Legend>

          <ButtonGroup
            variant="text"
            onSelect={onFontSizeSelect}
            selectedOptionValue={textTool.properties.fontSize.toString()}
            options={textTool.controlMap.fontSize.options.map((fontSizeOption) => ({
              name: fontSizeOption.label,
              value: fontSizeOption.value,
              color: editor.store.activeTool?.properties.textColor
            }))}
          />
        </Fieldset>
      ) : null}
    </>
  )
}
