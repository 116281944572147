import { ApiPaginationParams } from "../../api/types";

export const parsePaginationParams = (url: string | null): ApiPaginationParams => {
  if (!url) {
    return emptyPaginationParams();
  }

  const searchParamsString = url.split('?')[1];
  const searchParams = new URLSearchParams(searchParamsString);

  const pageQueryId = searchParams.get('pageQueryId') ?? null;
  const pageCount = Number.parseInt(searchParams.get('pageCount') ?? '') || null;

  return {
    pageCount,
    pageQueryId
  }
}

export const emptyPaginationParams = () => {
  return {
    pageCount: null,
    pageQueryId: null
  }
}
