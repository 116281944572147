import { apiClient } from "../../apiClient"
import { ApiMessageResponse } from "../../types";

export type ApiUploadDrawingToPatientEncounterResponse = ApiMessageResponse;
export type ApiUploadDrawingToPatientEncounterRequest = {
  patientResourceId: string,
  encounterResourceId: string,
  drawingImageBlob: Blob, // Defaults to 20 server-side.
};

export const apiUploadDrawingToPatientEncounter = async (
  request: ApiUploadDrawingToPatientEncounterRequest
): Promise<ApiUploadDrawingToPatientEncounterResponse> => {
  const formData = new FormData();
  formData.append("patientResourceId", request.patientResourceId);
  formData.append("encounterResourceId", request.encounterResourceId);
  formData.append("drawingImageFile", request.drawingImageBlob, `drawing-${Date.now()}.png`);

  const path = "/patientencounters/";
  const response = await apiClient.postForm(path, formData);

  return response.data;
}
