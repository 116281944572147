import { Footer, Header } from "nhsuk-react-components"
import { Outlet } from "react-router-dom"
import { MyAccount } from "../auth/MyAccount"
import { version } from "../../version"

export const AppMainLayout = () => {
  return (
    <div>
      <Header
        orgName="The Rotherham"
        orgSplit="NHS Foundation Trust"
        orgDescriptor="Annotated Diagrams"
      >
        <Header.Container>
          <Header.Logo href="/" />
          <Header.Content>
            <MyAccount />
          </Header.Content>
        </Header.Container>
      </Header>

      <div className="nhsuk-width-container-fluid app-main-layout--body">
        <Outlet />
      </div>

      <Footer>
        <Footer.List>
          <Footer.ListItem href="/">Home</Footer.ListItem>
          <Footer.ListItem href="https://thecurve.io" target="_blank">Powered by The Curve</Footer.ListItem>
        </Footer.List>

        <Footer.List>
          <Footer.ListItem>Version: {version}</Footer.ListItem>
        </Footer.List>

        <Footer.Copyright>&copy; Crown copyright</Footer.Copyright>
      </Footer>
    </div>
  )
}
