import { Graphics as PixiGraphics, RenderTexture as PixiRenderTexture, RenderTexture } from "pixi.js";
import type { Tool } from "./tools/Tool";
import { EditorStore, useEditorStore } from "../../data/editor.store";

export class Editor {
  renderTexture: PixiRenderTexture;

  brushGraphics: PixiGraphics = new PixiGraphics();
  pointerGraphics?: PixiGraphics;
  textGraphics?: PixiGraphics;

  tools: Tool[] = [];
  activeTool?: Tool;

  public get store(): EditorStore {
    return useEditorStore.getState();
  }

  constructor(renderTexture: RenderTexture) {
    this.renderTexture = renderTexture;
  }

  setBrushGraphics(brushGraphics: PixiGraphics) {
    if (this.brushGraphics !== brushGraphics) {
      // Destroy the previous graphics
      this.brushGraphics.destroy();
    }

    this.brushGraphics = brushGraphics;
  }

  setPointerGraphics(pointerGraphics: PixiGraphics) {
    this.pointerGraphics = pointerGraphics;
  }

  setTextGraphics(textGraphics: PixiGraphics) {
    this.textGraphics = textGraphics;
  }

  setTools(tools: Tool[]) {
    this.tools = tools;

    const defaultTool = this.tools.find((tool) => tool.isActive);
    if (defaultTool) {
      // If a preffered active tool is set, use that
      this.activeTool = defaultTool;
    } else if (tools[0]) {
      // If no preffered active tool is set, use the first tool
      tools[0].activate();
      this.activeTool = tools[0];
    }
  }

  /**
   * Activates a tool matching the provided {@link toolId}
   *
   * @param toolId
   * @returns the activated tool.
   */
  setActiveToolById(toolId: string): Tool | undefined {
    const newTool = this.tools.find(tool => tool.id === toolId);

    if (!newTool) {
      return undefined;
    }

    // Deactive the current tool, if activated (should be activated :)
    this.activeTool?.deactivate();

    // Activate the new tool
    newTool.activate();
    this.activeTool = newTool;

    return newTool;
  }
}
