export const secrets = {
  // Azure
  REACT_APP_AZURE_ACTIVE_DIRECTORY_LOGIN_REDIRECT_PATH: "auth/login/complete",
  REACT_APP_AZURE_ACTIVE_DIRECTORY_TENANT_ID: "d3db4717-2853-4110-bc89-f41b4c7eb1c6",
  REACT_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID: "12a81cf9-cd9a-4b92-800b-dc63ec2dcd86",
  // Errbit
  REACT_APP_ERRBIT_HOST: 'https://errbit.tccs.io',
  REACT_APP_ERRBIT_PROJECT_ID: '1',
  REACT_APP_ERRBIT_PROJECT_KEY: '9451f4519c14be986d77f5a4324e75c5',
} as const;

export type Secrets = typeof secrets
