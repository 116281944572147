import React, { ReactNode } from 'react'
import { Editor } from '../editor/Editor'
import { useCursor } from './useCursor'

type EditorProviderProps = {
  editor: Editor,
  children: ReactNode
}

const EditorContext = React.createContext({} as Editor)

export const EditorProvider = ({ editor, children }: EditorProviderProps) => {
	useCursor();

  return (
    <EditorContext.Provider
      value={editor}
      children={children} />
  )
}

export const useEditor = (): Editor => {
	return React.useContext(EditorContext)
}
