import { PropsWithChildren, useEffect, useRef } from 'react';
import { Button } from 'nhsuk-react-components';
import { HorizontalRule } from './HorizontalRule';
import './Modal.scss';

type ModalProps = {
  open: boolean;
  primaryAction?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  }
  canClose?: boolean;
  onClose: () => void;
}

export const Modal = ({ children, open, primaryAction, canClose = true, onClose }: PropsWithChildren<ModalProps>) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (open) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [open, ref]);

  return (
    <dialog className="modal" ref={ref} onCancel={onClose}>
      <div className="modal--container">
        <div className="modal--body">
          {children}
        </div>

        <HorizontalRule />

        <div className="modal--actions">
          {primaryAction ? (
            <Button
              className="modal--actions--button"
              onClick={primaryAction.onClick}
              disabled={primaryAction.disabled}
            >
              {primaryAction.label}
            </Button>
          ) : null}

          <Button
            className="modal--actions--button"
            onClick={onClose}
            secondary={primaryAction !== undefined}
            disabled={!canClose}
          >
            Close
          </Button>
        </div>
      </div>
    </dialog>
  );
}
