import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import { resetActiveAccount } from '../auth/resetActiveAccount';

/**
 * A hook that manages the active account state if needed.
 * - If authenticated and the active account is not set, then this hook sets it.
 * - This hook is meant to be used at the root of the application.
 */
export const useActiveAccountManager = (): void => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    // When authenticated and no active account is set,
    // attempt to set it to be the first found logged in account.
    resetActiveAccount(instance, isAuthenticated);
  }, [instance, isAuthenticated]);

  return;
}
