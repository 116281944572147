import { Header } from "nhsuk-react-components";
import { Outlet, useLocation } from "react-router-dom";
import { MyAccount } from "../auth/MyAccount";
import { Patient } from "../../api/resources/types";
import "./AppSimpleLayout.scss";

export type PatientPageState = {
  patient: Patient;
}

export const AppSimpleLayout = () => {
  return (
    <div className="container">
      <Header serviceName="Annotated Diagrams">
        <Header.Container>
          <Header.Logo href="/" />
          <Header.Content>
            <MyAccount />
          </Header.Content>
        </Header.Container>
      </Header>

      <Outlet />
    </div>
  );
}

export const AppSimplePatientLayout = () => {
  const { patient } = useLocation().state as PatientPageState;

  /**
   * Calculate the age of the patient from their birth date.
   * @param date The patient birth date.
   * @returns Their age in years.
   */
  const age = (date: Date) => {
    const ms = new Date(Date.now() - date.getTime());
    return Math.abs(ms.getUTCFullYear() - 1970);
  }

  /**
   * Capitalise the first letter of a word or phrase.
   * @param word The word or phrase.
   * @returns The capitalised word or phrase.
   */
  const capitalize = (word: string | null) => {
    if (!word) return "WT";
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }

  const date = new Date(patient.birthDate);
  const [, m, d, y] = date.toDateString().split(" ");

  const [givenName, familyName] = patient.fullName.split(" ");

  const fullName = `${familyName.toUpperCase()} ${capitalize(givenName)} (${capitalize(patient.prefix)})`;

  const nhsNumber = patient.nhsNumber ? patient.nhsNumber.replace(/(.{3})(?=.{3})/g, '$1 ') : 'N/A';

  return (
    <div className="container">
      <Header serviceName="Annotated Diagrams">
        <Header.Container>
          <Header.Logo href="/" />
          <Header.Content>
            <MyAccount />
          </Header.Content>
        </Header.Container>
      </Header>

      <div className="banner--mobile">
        <div className="nhsuk-width-container banner__container banner__container--mobile">
          <div className="banner__container__row">
            <span className="banner__container__row__bold">{patient.fullName}</span>
            <span>{age(date)} years old, {capitalize(patient.gender)}</span>
          </div>
          <div className="banner__container__row">
            <span className="banner__container__row__thin">Date of birth:</span>
            <span>{Number(d)}-{m}-{y}</span>
          </div>
          <div className="banner__container__row">
            <span className="banner__container__row__thin">NHS number:</span>
            <span>{nhsNumber}</span>
          </div>
          <div className="banner__container__row">
            <span className="banner__container__row__thin">Address:</span>
            <span>{patient.homeAddress.join(', ')}</span>
          </div>
        </div>
      </div>

      <div className="banner">
        <div className="nhsuk-width-container banner__container">
          <div className="banner__container__column banner__container__column--dark">
            <span className="banner__container__column__bold">NHS number:</span>
            <span>{nhsNumber}</span>
          </div>
          <div className="banner__container__column">
            <span className="banner__container__column__bold">Patient:</span>
            <span className="tooltip">
              {fullName}
              <span className="tooltip__span">
                <span className="tooltip__span__span">
                  <div className="tooltip__contents">
                    <h4>Patient details</h4>

                    <h6>NHS number</h6>
                    <p>{nhsNumber}</p>

                    <h6>Address</h6>
                    {patient.homeAddress.map((line, i) => (
                      <p key={`address-${i}`}>{line}</p>
                    ))}
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div className="banner__container__column">
            <span className="banner__container__column__bold">Gender:</span>
            <span>{capitalize(patient.gender)}</span>
          </div>
          <div className="banner__container__column">
            {age(date)} years ({Number(d)} {m} {y})
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};
