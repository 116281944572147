import { IPublicClientApplication } from "@azure/msal-browser";

/**
 * Ensures that an active account is selected when authenticated, by setting one if available.
 *
 * @param instance - The current instance of the public MSAL client (either {@link cachedMsalInstance}
 * or {@link useMsal().instance}).
 * @param isAuthenticated - the current authentication state (the value of {@link useIsAuthenticated()}).
 */
export const resetActiveAccount = (instance: IPublicClientApplication, isAuthenticated: boolean): void => {
  if (!isAuthenticated) {
    // Do nothing. Not authenticated.
    return;
  }

  // When authenticated but no active account is set
  // then attempt to set the first logged in account.
  if (!instance.getActiveAccount()) {
    // TODO?: The process of selecting the account here should probably change if / when the app
    // TODO?: starts supporting multi-account logins, and possibly be based on what the user selects
    // TODO?: rather than choosing the random first account from {@link instance#getAllAccounts}.
    instance.setActiveAccount(instance.getAllAccounts()[0]);
  }
}
