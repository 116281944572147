import { PointerEvent } from "react";
import { Tool } from "./Tool";
import type { BaseToolAction, ToolCursorType } from "./Tool.types";
import { Point } from "../../geometry/Point";
import { ToolControlMap } from "./ToolControl.types";
import { Graphics as PixiGraphics } from "pixi.js";
import { TRANSPARENT_COLOR } from "../colors";
import cloneDeep from "lodash.clonedeep";
import { pointerCoordinateFromEvent } from "../pointerCoordinateFromEvent";

export type RectangleShapeToolId = 'rectangle-shape';

export type RectangleShapeToolProperties = {
  strokeColor: string;
  fillColor: string;
  strokeSize: number;
}

export type ModifiableRectangleShapeToolProperties = Pick<
  RectangleShapeToolProperties, 'strokeColor' | 'fillColor' | 'strokeSize'
>;

export interface RectangleShapeToolAction extends BaseToolAction {
  toolId: RectangleShapeToolId;
  values: {
    points: Point[];
    properties: RectangleShapeToolProperties;
  }
}

export class RectangleShapeTool extends Tool<RectangleShapeToolProperties, ModifiableRectangleShapeToolProperties> {
  override id: RectangleShapeToolId = 'rectangle-shape';
  override type = 'shape';
  override name = 'Rectangle shape';
  override cursor: ToolCursorType = 'crosshair';

  override properties: RectangleShapeToolProperties = {
    strokeColor: 'black',
    fillColor: TRANSPARENT_COLOR,
    strokeSize: 8
  }

  override controlMap: ToolControlMap<ModifiableRectangleShapeToolProperties> = {
    strokeColor: {
      type: 'choices',
      options: [
        { label: 'Black', value: 'black' },
        { label: 'Red', value: '#79155B' },
        { label: 'Green', value: 'green' },
        { label: 'Blue', value: 'blue' },
        { label: 'Orange', value: 'orange' },
      ]
    },
    fillColor: {
      type: 'choices',
      options: [
        { label: 'None', value: TRANSPARENT_COLOR },
        { label: 'Black', value: 'black' },
        { label: 'Red', value: 'red' },
        { label: 'Green', value: 'green' },
        { label: 'Blue', value: 'blue' },
        { label: 'Orange', value: 'orange' },
      ]
    },
    strokeSize: {
      type: 'choices',
      options: [
        { label: 'S', value: '4' },
        { label: 'M', value: '8' },
        { label: 'L', value: '12' },
        { label: 'XL', value: '16' },
      ]
    }
  }

  override propertySetters: SetterFns<ModifiableRectangleShapeToolProperties> = {
    setFillColor: (fillColor) => {
      this.properties.fillColor = fillColor;
    },

    setStrokeColor: (strokeColor) => {
      this.properties.strokeColor = strokeColor;
    },

    setStrokeSize: (strokeSize) => {
      this.properties.strokeSize = strokeSize;
    }
  };

  /**
   * Whether or not the pointer is being pressed (held down) on the canvas.
   */
  isPressing: boolean = false;

  /**
   * Whether or not the rectangle is being drawn on the canvas.
   * - It gets e
   */
  isDrawing: boolean = false;

  /**
   * Maximum of 2 points, where the first point (A) is the top-left and the second
   * point is the bottom-right (B) point.
   *
   *  A-----+
   *  |     |
   *  +-----B
   */
  points: Point[] = [];

  onPointerMove(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.brushGraphics) {
      return;
    }

    if (this.isPressing) {
      this.isDrawing = true;
    }

    if (this.isDrawing) {
      this.points[1] = pointerCoordinateFromEvent(event);

      RectangleShapeTool.draw(this.editor.brushGraphics, this.points, this.properties);
    }
  }

  onPointerUp(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = false;
    this.isDrawing = false;

    if (this.points.length === 2) {
      this.editor.store.appendActionToHistory(
        this.recordAction({
          points: this.points,
          properties: this.properties
        })
      );
    }

    // Clear the last drawing segments
    this.points = [];
  }

  onPointerDown(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = true;

    this.points[0] = pointerCoordinateFromEvent(event);
  }

  onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.pointerGraphics) {
      return;
    }

    this.editor.pointerGraphics.clear();
    this.editor.pointerGraphics.beginFill(this.properties.strokeColor);

    const { x, y } = pointerCoordinateFromEvent(event);
    this.editor.pointerGraphics.drawCircle(x, y, this.properties.strokeSize / 2);
  }

  static draw(graphics: PixiGraphics, points: Point[], properties: RectangleShapeToolProperties) {
    const firstPoint = points[0];
    const secondPoint = points[1];
    const width = secondPoint.x - firstPoint.x;
    const height = secondPoint.y - firstPoint.y;

    let position: Point = { ...secondPoint };
    if (width < 0) {
      position.x = secondPoint.x;
    } else {
      position.x = firstPoint.x;
    }

    if (height < 0) {
      position.y = secondPoint.y;
    } else {
      position.y = firstPoint.y;
    }

    graphics.clear();
    graphics.lineStyle({
      color: properties.strokeColor,
      width: properties.strokeSize
    });

    if (properties.fillColor !== TRANSPARENT_COLOR) {
      graphics.beginFill(properties.fillColor);
    }

    graphics.drawRect(position.x, position.y, Math.abs(width), Math.abs(height));
    graphics.endFill();
  }

  private recordAction(values: RectangleShapeToolAction['values']): RectangleShapeToolAction {
    return {
      toolId: this.id,
      values: cloneDeep(values)
    }
  }
}

/**
 * A guard to check whether or not the given {@link tool} is a {@link RectangleShapeTool}.
 *
 * @param tool - The tool to check.
 * @returns `true` if {@link tool} is a {@link RectangleShapeTool}, otherwise `false`.
 */
export const isRectangleShapeTool = (tool: Pick<Tool, 'id'>): tool is RectangleShapeTool => {
  return tool.id === 'rectangle-shape';
}
