import { create } from 'zustand';
import { Tool } from "../lib/editor/tools/Tool";
import { setterify } from '../lib/common/setterify';
import { ToolAction, ToolActionHistory } from '../lib/editor/tools/Tool.types';

export type EditorStore = {
  tools: Tool[];
  activeTool?: Tool;
  history: ToolActionHistory;

  /**
   * The current point in history (index of {@link history}).
   * based on the number of undos and redos performed.
   * - If undo is performed, then this value is decreased by one, but only if this > 0.
   * - If redo is performed, then this value is increased by one if this < (history.length - 1).
   */
  currentPointInHistory: number;
  lastPointInHistoryChangeMode: 'none' | 'append' | 'remove' | 'undo' | 'redo' | 'clear',

  setTools: (tools: Tool[]) => void;
  setActiveToolById: (toolId: string) => void;
  disableActiveTool: () => void;
  updateActiveToolProps: <T extends object>(toolProperty: T) => void;

  canUndo: () => boolean;
  canRedo: () => boolean;
  appendActionToHistory: (toolAction: ToolAction) => void;
  removeActionFromHistory: () => void;
  undoLatestActionFromHistory: () => ToolAction[];
  redoLatestActionFromHistory: () => ToolAction[];
  clearHistory: () => void;
  getHistoryUntilCurrentPoint: () => ToolAction[];
}

export const useEditorStore = create<EditorStore>()(
  (set, get) => ({
    tools: [],
    activeTool: undefined,
    history: [],
    currentPointInHistory: -1,
    lastPointInHistoryChangeMode: 'none',

    canUndo: (): boolean => {
      return get().currentPointInHistory >= 0;
    },

    canRedo: (): boolean => {
      const { history, currentPointInHistory } = get();

      return currentPointInHistory < (history.length - 1);
    },

    appendActionToHistory: (toolAction: ToolAction) => {
      set((state) => {
        const historyUpUntilCurrentPoint = state.getHistoryUntilCurrentPoint();

        return {
          history: [...historyUpUntilCurrentPoint, toolAction],
          currentPointInHistory: state.currentPointInHistory + 1,
          lastPointInHistoryChangeMode: 'append',
        }
      });
    },

    removeActionFromHistory: () => {
      set((state) => {
        const historyUpUntilCurrentPoint = state.getHistoryUntilCurrentPoint();
        historyUpUntilCurrentPoint.pop();

        return {
          history: historyUpUntilCurrentPoint,
          currentPointInHistory: state.currentPointInHistory - 1,
          lastPointInHistoryChangeMode: 'remove',
        }
      });
    },

    undoLatestActionFromHistory: (): ToolAction[] => {
      const { currentPointInHistory, canUndo, getHistoryUntilCurrentPoint } = get();

      if (!canUndo()) {
        // Nothing left to undo.
        return getHistoryUntilCurrentPoint();
      }

      set(() => ({
        currentPointInHistory: currentPointInHistory - 1,
        lastPointInHistoryChangeMode: 'undo',
      }));

      return getHistoryUntilCurrentPoint();
    },

    redoLatestActionFromHistory: (): ToolAction[] => {
      const {
        history,
        lastPointInHistoryChangeMode,
        currentPointInHistory,
        canRedo,
        getHistoryUntilCurrentPoint
      } = get();

      if (!canRedo()) {
        // Nothing left to redo.
        return getHistoryUntilCurrentPoint();
      }

      // Check whether we're redoing just after the board having been cleared
      if (currentPointInHistory === -1 && lastPointInHistoryChangeMode === 'clear') {
        // Redo back to the full state that is was before clearing
        set(() => ({
          currentPointInHistory: history.length - 1,
          lastPointInHistoryChangeMode: 'redo',
        }));

      } else {
        // Else redo as normal, to the previously undone action.
        set(() => ({
          currentPointInHistory: currentPointInHistory + 1,
        }));
      }

      return getHistoryUntilCurrentPoint();
    },

    clearHistory: (): void => {
      const { canUndo, getHistoryUntilCurrentPoint } = get();

      if (!canUndo()) {
        // Nothing left to clear.
        return;
      }

      const frozenHistory = getHistoryUntilCurrentPoint();

      set(() => ({
        currentPointInHistory: -1,
        history: frozenHistory,
        lastPointInHistoryChangeMode: 'clear',
      }));
    },

    getHistoryUntilCurrentPoint: (): ToolAction[] => {
      const { history, currentPointInHistory } = get();

      if (currentPointInHistory === -1) {
        return [];
      }

      return history.slice(0, currentPointInHistory + 1);
    },

    setTools: (tools) => {
      set({ tools });
    },

    setActiveToolById: (toolId: string) => {
      const { tools, activeTool } = get();

      const newTool = tools.find(tool => tool.id === toolId);
      if (!newTool) {
        return;
      }

      // Deactive the current tool
      activeTool?.deactivate();

      // Activate the new tool
      newTool.activate();
      set({ activeTool: newTool });
    },

    disableActiveTool: () => {
      const { activeTool } = get();
      activeTool?.deactivate();
      set({ activeTool: undefined });
    },

    updateActiveToolProps: (updatedToolProps) => {
      const { activeTool } = get();
      if (!activeTool) {
        return;
      }

      for (const key in updatedToolProps) {
        const setterMethodName = setterify(key);
        const value = updatedToolProps[key];

        activeTool.propertySetters[setterMethodName as any]?.(value);
      }

      set({ activeTool });
    }
  })
);

