import React from "react";
import { Application } from "pixi.js";
import { PixiComponent, useApp } from "@pixi/react";
import { Viewport as PixiViewport } from "pixi-viewport";

const plugins = ['drag', 'pinch', 'wheel'] as const;

let instance: PixiViewport;

// Pixel dimensions at 300 DPI.
export const A4 = {
  width: 3508,
  height: 2480,
}

export interface ViewportProps {
  active: boolean;
  width: number;
  height: number;
  children?: React.ReactNode;
}

export interface PixiComponentViewportProps extends ViewportProps {
  app: Application;
}

export const viewport = (props?: PixiComponentViewportProps) => {
  if (!instance && props) {
    instance = new PixiViewport({
      screenWidth: props.width,
      screenHeight: props.height,
      worldWidth: A4.width,
      worldHeight: A4.height,
      ticker: props.app.ticker,
      events: props.app.renderer.events,
    });
  }

  return instance;
}

const PixiComponentViewport = PixiComponent("Viewport", {
  create: (props: PixiComponentViewportProps) => {
    const instance = viewport(props);

    plugins.forEach((plugin) => {
      instance[plugin]();
    });

    const wRatio = A4.width / props.width;
    const hRatio = A4.height / props.height;
    
    // Only include one or the other to preserve aspect-ratio.
    instance.snapZoom({
      ...(wRatio >= hRatio && { width: props.width * wRatio }),
      ...(hRatio >= wRatio && { height: props.height * hRatio }),
      time: 1,
      removeOnInterrupt: true,
      removeOnComplete: true,
    });
    instance.moveCenter(A4.width / 2, A4.height / 2);

    return instance;
  },
  applyProps: (instance, _, { active }) => {
    plugins.forEach((plugin) => instance.plugins[active ? 'resume' : 'pause'](plugin));
  }
});

const Viewport = (props: ViewportProps) => {
  const app = useApp();
  return <PixiComponentViewport app={app} {...props} />;
};

export default Viewport;
