import { AccountInfo } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { resetActiveAccount } from '../auth/resetActiveAccount';

type Authenticated = { authenticated: true, info: AccountInfo }
type Unauthenticated = { authenticated: false }
type MyAccount = Authenticated | Unauthenticated;

/**
 * When authenticated, returns the user information, otherwise doesn't and just
 * indicates that the user is currently unauthenticated.
 */
export const useAuth = (): MyAccount => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [accountInfo, setAccount] = useState<AccountInfo | null>();

  useEffect(() => {
    // Ensure an active account is selected when authenticated.
    resetActiveAccount(instance, isAuthenticated);

    if (isAuthenticated && instance.getActiveAccount()) {
      setAccount(instance.getActiveAccount());
    }
  }, [instance, isAuthenticated]);

  if (!isAuthenticated || !accountInfo) {
    return { authenticated: false }
  }

  return {
    authenticated: true,
    info: accountInfo,
  }
}
