/**
 * Capitalizes the first letter of the text and maintains the case for the rest.
 *
 * @param text - the text to capitalize.
 * @example
 * ```ts
 * const text: string = 'textColor';
 * const capitalizedText: string = capitalize(text);
 * //=> 'TextColor'
 * ```
 */
export const capitalize = (text: string): string => {
  if (!text.length) {
    return text;
  }

  const firstLetter = text[0].toUpperCase();
  const restOfLetters = text.substring(1, text.length);

  return `${firstLetter}${restOfLetters}`;
}
