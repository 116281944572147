import { AxiosError } from 'axios';
import { cachedAirbrakeNotifierInstance } from './cachedAirbrakeNotifier';



export const reportErrorToAirbrake = (error: unknown, description?: string) => {
  if (error instanceof AxiosError) {
    error = new Error(JSON.stringify(error.response));
  }

  const errorNotification = {
    error: error,
    params: { info: description }
  };

  cachedAirbrakeNotifierInstance().notify(errorNotification)
    .catch(() => {
      console.warn("Failed to report error to aibrake: ", error);
    });
};
