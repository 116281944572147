import { HTMLAttributes } from 'react';
import classnames from 'classnames';
import './LoadingSpinner.scss';

/**
 * @file This is a custom component added by The Curve, as the `nhsuk-react-components` did not provided one.
 *
 * Use case:
 * - Display a loading spinner while data is being fetched over the wire, or an action might take more a second or so to
 * finish executing.
 *
 * @see https://github.com/nhsuk/nhsuk-service-manual-community-backlog/issues/255 - To see where the inspiration for
 * this design was taken.
 */

type LoadingSpinnerProps = HTMLAttributes<HTMLDivElement> & Required<Pick<HTMLAttributes<HTMLDivElement>, 'aria-label'>> & {
  /**
   * The loading indicator color variant.
   *
   * @default primary
   */
  variant?: 'primary' | 'secondary' | 'tertiary';

  /**
   * The size of the loading indicator.
   *
   * @default medium"
   */
  size?: 'small' | 'medium' | 'large';

  /**
   * The label displayed underneath the loading indicator, presenting the content it's loading.
   * - When not provided it's hidden.
   *
   * @default undefined
   */
  label?: string;
}

export const LoadingSpinner = ({ variant = 'primary', size = 'medium', label, ...rest }: LoadingSpinnerProps) => {
  return (
    <div className="loading-spinner">
      <div
        {...rest}
        role="progressbar"
        className={
          classnames(
            'loading-spinner--spinner',
            `loading-spinner--spinner--size--${size}`,
            `loading-spinner--spinner--variant--${variant}`
          )
        }
        aria-valuemin={0}
        aria-valuemax={100}
      />

      {label ? <p className="loading-spinner--label">{label}</p> : null}
    </div>
  )
}
