import { TextTool, isTextTool } from "../lib/editor/tools/TextTool";
import { EditorStore } from "./editor.store";

export const selectTextToolIfActive = (store: EditorStore): TextTool | undefined => {
  if (!store.activeTool || !isTextTool(store.activeTool)) {
    return undefined;
  }

  return store.activeTool;
}
