import { Outlet } from 'react-router-dom';
import { useAuth } from '../../lib/hooks/useAuth';

export const ProtectedRoute = () => {
  const { authenticated } = useAuth();

  if (!authenticated) {
    return null;
  }

  return <Outlet />
}
