/**
 * Converts degrees to radians.
 *
 * Takes angle in degrees and returns its equivalent value in radians.
 *
 * @param degrees - The angle in degrees to be converted to radians.
 * @returns The equivalent angle in radians.
 *
 * @example
 * ```
 * const degrees: number = 90;
 * const radians: number = degreesToRadians(degrees);
 * //=> Output: 1.5707963267948966
 * ```
 */
export const degreesToRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180);
}
