import { KeyboardEvent, PointerEvent } from "react";
import { Tool } from "./Tool";
import { Point } from "../../geometry/Point";
import { ToolControlMap } from "./ToolControl.types";
import { Graphics as PixiGraphics, TextStyle as PixiTextStyle, TextMetrics as PixiTextMetrics, Text as PixiText, Container as PixiContainer } from "pixi.js";
import { Key } from "ts-key-enum";
import type { BaseToolAction, ToolCursorType } from "./Tool.types";
import { CharacterWidthCache, DistancesToCharactersPerLine, measureDistancesToCharactersPerLine } from "../../geometry/measureDistanceToCharactersPerLine";
import { TextStore, useTextStore } from "../../../data/text.store";
import { nanoid } from "nanoid";
import isEmpty from "lodash.isempty";
import cloneDeep from "lodash.clonedeep";
import { pointerCoordinateFromEvent } from "../pointerCoordinateFromEvent";

export type TextToolId = 'text';

export type TextToolProperties = {
  text: string;
  textColor: string;
  fontSize: number;
  boxWidth: number;
}

export type ModifiableTextToolProperties = Pick<TextToolProperties, 'text' | 'textColor' | 'fontSize'>;

export interface TextToolAction extends BaseToolAction {
  toolId: TextToolId;
  values: {
    id: string;
    textMeasurements: TextToolMeasurements,
    point: Point;
    properties: TextToolProperties;
  }
}

export type PixiTextMetricsExtension = {
  distancesToCharactersPerLine: number[][];
}
export type TextToolMeasurements = PixiTextMetrics & PixiTextMetricsExtension;

export class TextTool extends Tool<TextToolProperties, ModifiableTextToolProperties> {
  static FONT_FAMILY: string = 'Frutiger W01';
  static TEXT_BORDER_WIDTH: number = 4;
  static TEXT_LINE_HEIGHT: number = 1.47368; // Inherrited from nhs-frontend's text input's line-height

  override id: TextToolId = "text";
  override type = 'text';
  override name = 'Text';
  override cursor: ToolCursorType = 'text';

  override properties: TextToolProperties = {
    text: '', // empty text by default
    textColor: 'black',
    fontSize: 32,
    boxWidth: 960,
  }

  override controlMap: ToolControlMap<ModifiableTextToolProperties> = {
    text: {
      type: 'text'
    },
    textColor: {
      type: "choices",
      options: [
        { label: 'Black', value: 'black' },
        { label: 'Green', value: 'green' },
        { label: 'Blue', value: 'blue' },
        { label: 'Orange', value: 'orange' },
      ]
    },
    fontSize: {
      type: 'choices',
      options: [
        { label: '32', value: '32' },
        { label: '48', value: '48' },
        { label: '64', value: '64' },
        { label: '80', value: '80' },
        { label: '96', value: '96' },
      ]
    },
  }

  override propertySetters: SetterFns<ModifiableTextToolProperties> = {
    setTextColor: (textColor) => {
      this.properties.textColor = textColor;
      this.measure();
    },

    setFontSize: (fontSize) => {
      this.properties.fontSize = fontSize;
      this.measure();
    },

    setText: (text) => {
      this.properties.text = text;
      this.measure();
    }
  };

  /**
   * Accessor for the latest text tool store state.
   */
  public get store(): TextStore {
    return useTextStore.getState();
  }

  private _measurement?: TextToolMeasurements;
  private _characterWidthCache: CharacterWidthCache = Object.create(null);

  onKeyUp(event: KeyboardEvent<HTMLCanvasElement>): void {
    // this.isTyping = false;
    // this.pointInCanvas = undefined;

    if (event.key === Key.Escape) {
      this.editor.brushGraphics.clear();
      this.editor.textGraphics?.clear();
    }
  }

  onPointerMove(event: PointerEvent<HTMLCanvasElement>): void {
  }

  onPointerUp(event: PointerEvent<HTMLCanvasElement>): void {
    if (this.store.isTyping) {
      // Was already typing when this pointer event got called,
      // so pressing again anywhere else should apply the text to the canvas.
      this.placeText();

    } else {
      // Begin typing at this position
      const pointInCanvas: Point = pointerCoordinateFromEvent(event);
      const { x, y } = event.currentTarget.getBoundingClientRect();
      const pointInDocument: Point = { x: event.pageX - x, y: event.pageY - y };
      this.store.startTypingAt(pointInCanvas, pointInDocument);
    }
  }

  onPointerDown(event: PointerEvent<HTMLCanvasElement>): void {
  }

  onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.pointerGraphics) {
      return;
    }

    this.editor.pointerGraphics.clear();
    this.editor.pointerGraphics.beginFill('black');

    const { x, y } = pointerCoordinateFromEvent(event);
    this.editor.pointerGraphics.drawCircle(x, y, 2);
  }

  static draw(graphics: PixiGraphics, point: Point, properties: TextToolProperties, textMeasurements: TextToolMeasurements, id: string) {
    graphics.clear();

    // The container that'll hold the bounding box and the actual text.
    const container = new PixiContainer();
    container.name = id;

    // The bounding box
    const boundingBoxGraphics = new PixiGraphics();
    boundingBoxGraphics.lineStyle({ color: properties.textColor, width: this.TEXT_BORDER_WIDTH });
    boundingBoxGraphics.drawRect(
      point.x + this.TEXT_BORDER_WIDTH - 4,
      point.y + this.TEXT_BORDER_WIDTH - 2,
      properties.boxWidth - this.TEXT_BORDER_WIDTH,
      textMeasurements.height + this.TEXT_BORDER_WIDTH + 4
    )

    // The text
    const text = new PixiText(properties.text, TextTool.getTextStyleForCurrentProperties(properties));
    text.position.set(
      point.x + this.TEXT_BORDER_WIDTH * this.TEXT_LINE_HEIGHT,
      point.y + this.TEXT_BORDER_WIDTH * this.TEXT_LINE_HEIGHT
    );

    // Add the text and bounding box to the container
    container.addChild(text, boundingBoxGraphics);

    // Add the container to the graphics
    graphics.addChild(container);
  }

  measure(): TextToolMeasurements {
    // if (this.cache.lastTextMetrics && (this.properties.text === this.cache.lastText)) {
    //   return this.cache.lastTextMetrics;
    // }

    const textStyle = TextTool.getTextStyleForCurrentProperties(this.properties);

    const distancesToCharacters: DistancesToCharactersPerLine = measureDistancesToCharactersPerLine(
      this.properties.text,
      textStyle,
      this._characterWidthCache
    )

    const measurements = PixiTextMetrics.measureText(this.properties.text, textStyle);

    const extendedMeasurements: TextToolMeasurements = Object.assign<PixiTextMetrics, PixiTextMetricsExtension>(
      measurements,
      { distancesToCharactersPerLine: distancesToCharacters }
    );

    this._measurement = extendedMeasurements;
    return this._measurement;
  }

  getTextMeasurements(): TextToolMeasurements {
    if (this._measurement) {
      return this._measurement;
    }

    this._measurement = this.measure();
    return this._measurement;
  }

  static getTextStyleForCurrentProperties(properties: TextToolProperties): PixiTextStyle {
    // if (this.cache.lastTextStyle && (this.properties.text === this.cache.lastText)) {
    //   return this.cache.lastTextStyle;
    // }

    return new PixiTextStyle({
      fill: properties.textColor,
      fontSize: properties.fontSize,
      wordWrap: true,
      wordWrapWidth: properties.boxWidth - TextTool.TEXT_BORDER_WIDTH,
      fontFamily: TextTool.FONT_FAMILY,
      lineHeight: properties.fontSize * TextTool.TEXT_LINE_HEIGHT,
    });
  }

  placeText(): void {
    const pointInCanvas = this.store.pointInCanvas;
    const textGraphics = this.editor.textGraphics;

    if (this.hasText() && textGraphics && pointInCanvas) {
      const id = nanoid();
      const textMeasurements = this.getTextMeasurements();
      TextTool.draw(textGraphics, pointInCanvas, this.properties, this.getTextMeasurements(), id);

      this.editor.store.appendActionToHistory(
        this.recordAction({
          id,
          point: pointInCanvas,
          textMeasurements,
          properties: this.properties
        })
      )
    }

    this.stopTypingAndReset();
  }

  private stopTypingAndReset(): void {
    // this.pointInCanvas = undefined;
    // this.isTyping = false;
    this.propertySetters.setText('');
    this.store.stopTypingAndReset();
  }

  private hasText(): boolean {
    return !isEmpty(this.properties.text.trim());
  }

  private recordAction(values: TextToolAction['values']): TextToolAction {
    return {
      toolId: this.id,
      values: cloneDeep(values)
    }
  }
}

/**
 * A guard to check whether or not the given {@link tool} is a {@link TextTool}.
 *
 * @param tool - The tool to check.
 * @returns `true` if {@link tool} is a {@link TextTool}, otherwise `false`.
 */
export const isTextTool = (tool: Pick<Tool, 'id'>): tool is TextTool => {
  return tool.id === 'text';
}
