import { Component, ErrorInfo, ReactNode } from 'react';
import { Container, Card, Button } from "nhsuk-react-components";
import { reportErrorToAirbrake } from '../../lib/airbrake/reportErrorToAirbrake';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class AirbrakeErrorBoundary extends Component<Props, State> {
  state: State = { hasError: false };

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    reportErrorToAirbrake(error, errorInfo.toString());
  }

  onReloadPage = () => {
    window.location.reload();
  };

  render(): ReactNode {
    if (this.state.hasError) {
        return (
          <Container>
            <Card feature={true}>
              <Card.Content>
                <Card.Heading>Something went wrong</Card.Heading>
                <Card.Description>
                  <p>An unexpected issue has occurred.</p>
                  <p>Please wait a short while before clicking the button below to reload the page.</p>
                  <p>If this issue does not resolve itself please contact your administrator.</p>
                  <Button onClick={this.onReloadPage} >Reload Page</Button>
                </Card.Description>
              </Card.Content>
            </Card>
          </Container>
        );
      }
    return this.props.children;
  }
}

export default AirbrakeErrorBoundary;
