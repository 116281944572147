import { EraserTool } from "../../../../lib/editor/tools/EraserTool"

type EraserToolControllerProps = {
  eraserTool: EraserTool;
}

export const EraserToolController = ({ eraserTool }: EraserToolControllerProps) => {
  return (
    <></>
  );
}
