import {getMsalAccessTokenRequest} from "../../config/msal/msalAccessTokenRequest";
import {cachedMsalInstance} from "./cachedMsalInstance"

export const getAccessTokenSilently = async (): Promise<string | undefined> => {
  const msalInstance = cachedMsalInstance();

  try {
    const msalAccessTokenRequest = getMsalAccessTokenRequest();
    const accessTokenResponse = await msalInstance.acquireTokenSilent(msalAccessTokenRequest);
    return accessTokenResponse.accessToken;
  } catch (error) {
    // Failed to acquire access token.
    // Even though the msalInstance allows for acquiring token via user insteraction if it fails, we
    // should not do that in this scenario. All interactions should take place underneath the MsalProvider component
    // in the component tree.
    console.error(error);
    return undefined;
  }
}
