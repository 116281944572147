import { Graphics as PixiGraphics } from "pixi.js";
import { ToolActionHistory } from "./tools/Tool.types";
import { RectangleShapeTool } from "./tools/RectangleShapeTool";
import { PencilTool } from "./tools/PencilTool";
import { EllipseShapeTool } from "./tools/EllipseShapeTool";
import { LineTool } from "./tools/LineTool";
import { ArrowTool } from "./tools/ArrowTool";
import { TextTool } from "./tools/TextTool";
import { PenTool } from "./tools/PenTool";
import { Point } from "../geometry/Point";
import { getBoundingBoxFromPoints, hasIntersect, hasIntersectWithEllipse, hasIntersectWithRect, hasOverlap } from "../geometry/BoundingBox";

/**
 * Applies the current tool action {@link history} onto the {@link graphics} in order.
 *
 * @param history - The list of actions performed that we want to apply to the {@link graphics}.
 * @param brushGraphics - The graphics instance that will receive all of the {@link history} actions for brushes.
 * @param textGraphics - The graphics instance that will receive all of the {@link history} actions for text.
 */
export const applyHistoryToGraphics = (
  history: ToolActionHistory,
  brushGraphics: PixiGraphics,
  textGraphics: PixiGraphics | undefined,
  onRender: () => void,
): void => {
  // Store the indexes of erased elements
  const ignore: Set<Number> = new Set<Number>();

  history.forEach((action, end) => {
    if (action.toolId === 'eraser') {
      const eraser = getBoundingBoxFromPoints(action.values.segments);

      // Extract point clouds from 
      history.slice(0, end).forEach((target, index) => {
        let points: Point[];
        switch (target.toolId) {
          case 'rectangle-shape':
            {
              // Create bounding box for rectangle shape
              const { x: x1, y: y1 } = target.values.points[0];
              const { x: x2, y: y2 } = target.values.points[1];
              points = [
                target.values.points[0], // top-left
                new Point(x1, y2), // top-right
                new Point(x2, y2), // bottom-right
                new Point(x2, y1), // bottom-left
                target.values.points[0], // top-left
              ];
              break;
            }
          case 'ellipse-shape':
            {
              // Create bounding box for ellipsis shape
              const { x, y } = target.values.points[0];
              const { x: x2, y: y2 } = target.values.points[1];
              const x1 = x - (x2 - x);
              const y1 = y - (y2 - y);
              points = [
                new Point(x1, y1), // top-left
                new Point(x1, y2), // top-right
                new Point(x2, y2), // bottom-right
                new Point(x2, y1), // bottom-left
                new Point(x1, y1), // top-left
              ];
              break;
            }
          case 'pen':
          case 'line':
          case 'arrow':
            points = target.values.points;
            break;
          case 'pencil':
            points = target.values.segments;
            break;
          case 'text':
            {
              // Create bounding box for text box
              const { x, y } = target.values.point;
              const w = target.values.properties.boxWidth;
              const h = target.values.textMeasurements.height;
              points = [
                target.values.point, // top-left
                new Point(x + w, y), // top-right
                new Point(x + w, y + h), // bottom-right
                new Point(x, y + h), // bottom-left
                target.values.point, //top-left
              ];
              break;
            }
          default:
            return;
        }

        const bounds = getBoundingBoxFromPoints(points);

        // Uses axis-aligned bounding boxes for first-pass to eliminate obvious non-overlaps
        if (hasOverlap(eraser, bounds)) {
          // Check if any point falls entirely within rectangular elements
          if (['rectangle-shape', 'text'].includes(target.toolId)) {
            action.values.segments.forEach((segment) => {
              if (hasIntersectWithRect(segment, bounds)) {
                if (!ignore.has(index)) {
                  ignore.add(index);
                  action.values.properties.erasedAny = true;
                }
                return;
              }
            });
            return;
          }

          // Check if any point falls entirely within elliptic elements
          if (target.toolId === 'ellipse-shape') {
            action.values.segments.forEach((segment) => {
              if (hasIntersectWithEllipse(segment, target.values.points[0], target.values.points[1])) {
                if (!ignore.has(index)) {
                  ignore.add(index);
                  action.values.properties.erasedAny = true;
                }
                return;
              }
            });
            return;
          }

          // Check if any eraser segment intersects a line segment
          for (let x = 1; x < action.values.segments.length; x++) {
            for (let y = 1; y < points.length; y++) {
              if (hasIntersect(
                action.values.segments[x - 1],
                action.values.segments[x],
                points[y - 1],
                points[y]
              )) {
                if (!ignore.has(index)) {
                  ignore.add(index);
                  action.values.properties.erasedAny = true;
                }
                return;
              }
            }
          }
        }
      });
    }
  });

  history.forEach((action, index) => {
    if (ignore.has(index)) {
      onRender();
      return;
    }

    if (action.toolId === 'rectangle-shape') {
      RectangleShapeTool.draw(brushGraphics, action.values.points, action.values.properties);

    } else if (action.toolId === 'pencil') {
      PencilTool.draw(brushGraphics, action.values.segments, action.values.properties);

    } else if (action.toolId === 'ellipse-shape') {
      EllipseShapeTool.draw(brushGraphics, action.values.points, action.values.properties);

    } else if (action.toolId === 'pen') {
      PenTool.draw(brushGraphics, action.values.points, action.values.properties);

    } else if (action.toolId === 'line') {
      LineTool.draw(brushGraphics, action.values.points, action.values.properties);

    } else if (action.toolId === 'arrow') {
      ArrowTool.draw(brushGraphics, action.values.points, action.values.properties);

    } else if (action.toolId === 'text' && textGraphics) {
      TextTool.draw(textGraphics, action.values.point, action.values.properties, action.values.textMeasurements, action.values.id);
    }

    onRender();
  });
}
