import { Notifier } from "@airbrake/browser";
import { secrets } from "../../config/secrets/secrets";

let airbrakeNotifierInstance: Notifier | undefined;

/**
 * Create and/or return existing Airbrake Notifier instance for use
 * throughout the app.
 */
export const cachedAirbrakeNotifierInstance = (): Notifier => {
  if (!airbrakeNotifierInstance) {
    airbrakeNotifierInstance = new Notifier({
      host: secrets.REACT_APP_ERRBIT_HOST,
      projectId: parseInt(secrets.REACT_APP_ERRBIT_PROJECT_ID, 10),
      projectKey: secrets.REACT_APP_ERRBIT_PROJECT_KEY,
      environment: process.env.NODE_ENV ?? 'development',
      remoteConfig: false
    });
  }

  return airbrakeNotifierInstance;
}
