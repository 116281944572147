import { PointerEvent } from "react";
import { Tool } from "./Tool";
import type { BaseToolAction, ToolCursorType } from "./Tool.types";
import { Point } from "../../geometry/Point";
import { ToolControlMap } from "./ToolControl.types";
import { Graphics as PixiGraphics } from "pixi.js";
import { TRANSPARENT_COLOR } from "../colors";
import cloneDeep from "lodash.clonedeep";
import { pointerCoordinateFromEvent } from "../pointerCoordinateFromEvent";

export type EllipseShapeToolId = 'ellipse-shape';

export type EllipseShapeToolProperties = {
  strokeColor: string;
  fillColor: string;
  strokeSize: number;
  width: number;
  height: number;
}

export type ModifiableEllipseShapeToolProperties = Pick<
  EllipseShapeToolProperties, 'strokeColor' | 'fillColor' | 'strokeSize'
>;

export interface EllipseShapeToolAction extends BaseToolAction {
  toolId: EllipseShapeToolId;
  values: {
    points: Point[];
    properties: EllipseShapeToolProperties;
  }
}

export class EllipseShapeTool extends Tool<EllipseShapeToolProperties, ModifiableEllipseShapeToolProperties> {
  override id: EllipseShapeToolId = 'ellipse-shape';
  override type = 'shape';
  override name = 'Ellipse shape';
  override cursor: ToolCursorType = 'crosshair';

  override properties: EllipseShapeToolProperties = {
    strokeColor: 'green',
    fillColor: TRANSPARENT_COLOR,
    strokeSize: 8,
    width: 100,
    height: 100
  }

  override controlMap: ToolControlMap<ModifiableEllipseShapeToolProperties> = {
    strokeColor: {
      type: 'choices',
      options: [
        { label: 'Black', value: 'black' },
        { label: 'Red', value: 'red' },
        { label: 'Green', value: 'green' },
        { label: 'Blue', value: 'blue' },
        { label: 'Orange', value: 'orange' },
      ]
    },
    strokeSize: {
      type: 'choices',
      options: [
        { label: 'S', value: '4' },
        { label: 'M', value: '8' },
        { label: 'L', value: '12' },
        { label: 'XL', value: '16' },
      ]
    },
    fillColor: {
      type: 'choices',
      options: [
        { label: 'None', value: TRANSPARENT_COLOR },
        { label: 'Black', value: 'black' },
        { label: 'Red', value: 'red' },
        { label: 'Green', value: 'green' },
        { label: 'Blue', value: 'blue' },
        { label: 'Orange', value: 'orange' },
      ]
    }
  }

  override propertySetters: SetterFns<ModifiableEllipseShapeToolProperties> = {
    setFillColor: (fillColor) => {
      this.properties.fillColor = fillColor;
    },

    setStrokeColor: (strokeColor) => {
      this.properties.strokeColor = strokeColor;
    },

    setStrokeSize: (strokeSize) => {
      this.properties.strokeSize = strokeSize;
    }
  };

  isPressing: boolean = false;
  isDrawing: boolean = false;

  points: Point[] = [];

  onPointerMove(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.brushGraphics) {
      return;
    }

    if (this.isPressing) {
      this.isDrawing = true;
    }

    if (this.isDrawing) {
      this.points[1] = pointerCoordinateFromEvent(event);

      EllipseShapeTool.draw(this.editor.brushGraphics, this.points, this.properties, event.shiftKey);
    }
  }

  onPointerUp(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = false;
    this.isDrawing = false;

    if (this.points.length === 2) {
      this.editor.store.appendActionToHistory(
        this.recordAction({
          points: this.points,
          properties: this.properties
        })
      );
    }

    // Clear the last drawing segments
    this.points = [];
  }

  onPointerDown(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = true;

    this.points[0] = pointerCoordinateFromEvent(event);
  }

  onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.pointerGraphics) {
      return;
    }

    this.editor.pointerGraphics.clear();
    this.editor.pointerGraphics.beginFill(this.properties.strokeColor);

    const { x, y } = pointerCoordinateFromEvent(event);
    this.editor.pointerGraphics.drawCircle(x, y, this.properties.strokeSize / 2);
  }

  static draw(graphics: PixiGraphics, points: Point[], properties: EllipseShapeToolProperties, forceCircle?: boolean) {
    const firstPoint = points[0];
    const secondPoint = points[1];
    let width = secondPoint.x - firstPoint.x;
    let height = secondPoint.y - firstPoint.y;

    if (forceCircle) {
      const max = Math.max(width, height);
      width = max;
      height = max;

      points[1] = { x: firstPoint.x + max, y: firstPoint.y + max }
    }

    graphics.clear();

    graphics.lineStyle({
      color: properties.strokeColor,
      width: properties.strokeSize
    });

    if (properties.fillColor !== TRANSPARENT_COLOR) {
      graphics.beginFill(properties.fillColor);
    }

    graphics.drawEllipse(firstPoint.x, firstPoint.y, Math.abs(width), Math.abs(height));
    graphics.endFill();
  }

  private recordAction(values: EllipseShapeToolAction['values']): EllipseShapeToolAction {
    return {
      toolId: this.id,
      values: cloneDeep(values)
    }
  }
}

/**
 * A guard to check whether or not the given {@link tool} is a {@link EllipseShapeTool}.
 *
 * @param tool - The tool to check.
 * @returns `true` if {@link tool} is a {@link EllipseShapeTool}, otherwise `false`.
 */
export const isEllipseShapeTool = (tool: Pick<Tool, 'id'>): tool is EllipseShapeTool => {
  return tool.id === 'ellipse-shape';
}
