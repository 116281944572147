import classNames from 'classnames';
import './AnchorButton.scss';

interface AnchorButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  reverse?: boolean
}

export const AnchorButton = ({ reverse, children, ...rest }: AnchorButtonProps) => {
  return (
    <button
      {...rest}
      className={
        classNames(
          'anchor-button',
          reverse ? 'anchor-button__reverse' : undefined
        )
      }
      children={children}
    />
  );
};
