import { useRef } from "react"

/**
 * A custom hook to {@link useRef} without having to call `.current` everytime we need to access the value,
 * instead, call the referenced value directly.
 * - In other words, this simply uses {@link useRef} under the hood and returns `.current` for you.
 * - This can be used for instantiating stateless Class instances for example as per the example below:
 *
 * @example
 * ```tsx
 * class Person {
 *   constructor(private name: string) {}
 * }
 *
 * const MyComponent = () => {
 *  const person = useRefWithoutCurrent(new Person());
 *
 *  const onSave = () =>{
 *    // Simply access the `person` referenced value (in this case, the Person class instance) without `.current`:
 *    person.name = "Lorem Ipsum";
 *
 *    ...
 *  }
 *
 *  return (
 *    <Button onClick={onSave}>
 *      Save
 *    </Button>
 *  )
 * }
 * ```
 *
 * @param initialValue - The initial value to use. {@see useRef#}
 *
 * @returns the `.current` value of the ref created with the `initialValue`.
 */
export const useRefWithoutCurrent = <TInitialValue>(initialValue: TInitialValue): TInitialValue => {
  const ref = useRef(initialValue);

  return ref.current;
}
