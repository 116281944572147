import { getDate, getMonth, getYear, isValid, parseISO } from "date-fns";

/**
 * This is equivalent to the type that the {@link <DateInput />} component from `nhs-react-frontend` uses.
 * - We're defining it here, because `nhs-react-frontend` does not export this type from the package.
 */
export type NhsDateInputValue = {
  day: string | undefined,
  month: string | undefined,
  year: string | undefined
};

export const parseNhsDateInputValueAsDate = (nhsDateInputValue: NhsDateInputValue): Date | undefined => {
  const isoFormattedDate = `${nhsDateInputValue.year}-${padWithLeadingZero(nhsDateInputValue.month)}-${padWithLeadingZero(nhsDateInputValue.day)}`;
  const date = parseISO(isoFormattedDate);

  if (!isValid(date)) {
    return undefined;
  }

  return date;
}

export const parseDateAsNhsDateInputValue = (date?: Date): NhsDateInputValue => {
  if (!date) {
    return emptyNhsDateInputValue();
  }

  return {
    day: padWithLeadingZero(getDate(date).toString()),
    month: padWithLeadingZero((getMonth(date) + 1).toString()), // getMonth is 0 indexed, as in, January is 0, February is 1 and so on...
    year: getYear(date).toString()
  }
}

/**
 * Pad a date parameter (day or month) with a leading `0`.
 */
export const padWithLeadingZero = (parameter?: string): string | undefined => {
  return parameter?.padStart(2, "0");
}

export const emptyNhsDateInputValue = (): NhsDateInputValue => {
  return {
    day: '',
    month: '',
    year: ''
  }
}
